import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { GripVertical } from 'lucide-react';

const AdminActivitiesPage = () => {
  const [activities, setActivities] = useState([]);
  const [newActivity, setNewActivity] = useState({ name: '' });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [draggedItem, setDraggedItem] = useState(null);

  const sortCompletedActivities = useCallback(async (activitiesList) => {
    try {
      const completedActivities = activitiesList
        .filter(activity => activity.completed)
        .sort((a, b) => {
          const dateA = new Date(a.completedAt || a.endDate);
          const dateB = new Date(b.completedAt || b.endDate);
          return dateB - dateA;
        });
      
      const incompleteActivities = activitiesList
        .filter(activity => !activity.completed)
        .sort((a, b) => a.precedence - b.precedence);
      
      return [...incompleteActivities, ...completedActivities];
    } catch (error) {
      console.error("Error sorting activities:", error);
      return activitiesList;
    }
  }, []);

  const fetchActivities = useCallback(async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${import.meta.env.VITE_API_BASE_URL}/api/activities`);
      const activitiesData = Array.isArray(response.data) ? response.data : response.data.activities || [];
      
      const completedActivities = activitiesData.filter(activity => activity.completed);
      const sortedCompleted = await sortCompletedActivities(completedActivities);
      const incompleteActivities = activitiesData.filter(activity => !activity.completed);
      
      setActivities([...incompleteActivities, ...sortedCompleted]);
      setError(null);
    } catch (error) {
      console.error("Error fetching activities:", error);
      setError("Failed to load activities");
      setActivities([]);
    } finally {
      setLoading(false);
    }
  }, [sortCompletedActivities]);

  useEffect(() => {
    fetchActivities();
  }, [fetchActivities]);

  const handleCreateActivity = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${import.meta.env.VITE_API_BASE_URL}/api/activities`, newActivity);
      setActivities(prev => [...prev, response.data]);
      setNewActivity({ name: '' });
      setError(null);
    } catch (error) {
      console.error("Error creating activity:", error);
      setError("Failed to create activity");
    }
  };

  const handleCompleteActivity = async (id) => {
    try {
      const response = await axios.patch(`${import.meta.env.VITE_API_BASE_URL}/api/activities/${id}/complete`);
      const updatedActivities = activities.map(activity =>
        activity._id === id ? { 
          ...activity, 
          completed: true, 
          endDate: response.data.endDate,
          completedAt: response.data.completedAt 
        } : activity
      );
      const sortedActivities = await sortCompletedActivities(updatedActivities);
      setActivities(sortedActivities);
      setError(null);
    } catch (error) {
      console.error("Error completing activity:", error);
      setError("Failed to complete activity");
    }
  };

  const handleDeleteActivity = async (id) => {
    try {
      await axios.delete(`${import.meta.env.VITE_API_BASE_URL}/api/activities/${id}`);
      setActivities(prev => prev.filter(activity => activity._id !== id));
      setError(null);
    } catch (error) {
      console.error("Error deleting activity:", error);
      setError("Failed to delete activity");
    }
  };

  const handleDragStart = (e, activity) => {
    if (activity.completed) {
      e.preventDefault();
      return;
    }
    setDraggedItem(activity);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = async (e, targetActivity) => {
    e.preventDefault();
    if (!draggedItem || draggedItem._id === targetActivity._id || targetActivity.completed) return;

    const draggedIndex = activities.findIndex(item => item._id === draggedItem._id);
    const targetIndex = activities.findIndex(item => item._id === targetActivity._id);
    
    try {
      const newActivities = [...activities];
      newActivities.splice(draggedIndex, 1);
      newActivities.splice(targetIndex, 0, draggedItem);
      
      const incompleteActivities = newActivities.filter(activity => !activity.completed);
      incompleteActivities.forEach((activity, index) => {
        activity.precedence = index + 1;
      });
      
      setActivities(newActivities);

      await axios.post(`${import.meta.env.VITE_API_BASE_URL}/api/activities/reorder`, {
        activityId: draggedItem._id,
        targetPosition: targetIndex,
        newOrder: incompleteActivities.map(activity => ({
          id: activity._id,
          precedence: activity.precedence
        }))
      });

      setError(null);
    } catch (error) {
      console.error("Error reordering activities:", error);
      setError("Failed to reorder activities");
      await fetchActivities();
    }

    setDraggedItem(null);
  };

  if (loading) {
    return (
      <div className="p-6 bg-gray-50 min-h-screen">
        <h2 className="text-2xl font-bold mb-6">Admin: Manage Activities</h2>
        <p>Loading activities...</p>
      </div>
    );
  }

  return (
    <div className="p-6 bg-gray-50 min-h-screen">
      <div className="max-w-4xl mx-auto">
        <h2 className="text-2xl font-bold mb-6">Manage Activities</h2>

        {error && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
            {error}
          </div>
        )}

        <form onSubmit={handleCreateActivity} className="mb-8 bg-white p-6 rounded-lg shadow">
          <div className="flex flex-col gap-4">
            <div>
              <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">
                Activity Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={newActivity.name}
                onChange={(e) => setNewActivity({ ...newActivity, name: e.target.value })}
                className="w-full p-2 border rounded focus:ring-blue-500 focus:border-blue-500"
                required
              />
            </div>

            <button
              type="submit"
              className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition-colors"
            >
              Add Activity
            </button>
          </div>
        </form>

        <div className="space-y-6">
          {activities.map((activity) => (
            <div
              key={activity._id}
              draggable={!activity.completed}
              onDragStart={(e) => handleDragStart(e, activity)}
              onDragOver={handleDragOver}
              onDrop={(e) => handleDrop(e, activity)}
              className={`bg-white p-4 rounded-lg shadow ${
                activity.completed ? 'opacity-75' : 'hover:shadow-lg transition-shadow'
              }`}
            >
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-3">
                  {!activity.completed && (
                    <svg 
                      xmlns="http://www.w3.org/2000/svg" 
                      className="h-6 w-6 text-gray-400 cursor-move" 
                      fill="none" 
                      viewBox="0 0 24 24" 
                      stroke="currentColor"
                    >
                      <path 
                        strokeLinecap="round" 
                        strokeLinejoin="round" 
                        strokeWidth={2} 
                        d="M8 9h8M8 15h8" 
                      />
                    </svg>
                  )}
                  <span className="text-lg">{activity.name}</span>
                </div>
                <div className="flex gap-2">
                  {!activity.completed && (
                    <button
                      onClick={() => handleCompleteActivity(activity._id)}
                      className="bg-green-500 text-white px-3 py-1 rounded hover:bg-green-600"
                    >
                      Complete
                    </button>
                  )}
                  <button
                    onClick={() => handleDeleteActivity(activity._id)}
                    className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600"
                  >
                    Delete
                  </button>
                </div>
              </div>
              {activity.completed && (
                <div className="text-sm text-gray-500 mt-2">
                  Completed on: {new Date(activity.endDate).toLocaleDateString()}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AdminActivitiesPage;