import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie'; // Import the js-cookie library
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const JournalCard = ({ journal }) => {
  const [imageLoading, setImageLoading] = useState(true);

  return (
    <div className="relative rounded-lg shadow-lg overflow-hidden">
      <div className="relative leftImg bg-[#D6E6FF] w-full md:w-[50rem] h-[40vh] xl:h-[10rem] xl:w-[50rem] rounded-2xl overflow-hidden">
        {imageLoading && (
          <div className="absolute inset-0 flex items-center justify-center bg-gray-100">
            <div className="w-8 h-8 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
          </div>
        )}
        <img
          src={journal.image}
          alt={journal.title}
          className={`w-full h-full object-cover transition-opacity duration-300 ${
            imageLoading ? 'opacity-0' : 'opacity-100'
          }`}
          onLoad={() => setImageLoading(false)}
          onError={() => setImageLoading(false)}
        />
      </div>
    </div>
  );
};

const Journal = () => {
  const [journals, setJournals] = useState({ latest: [], journals: {} });
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [imageLoadingStates, setImageLoadingStates] = useState({});

  const storedUserId = Cookies.get('Userid');
  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    const fetchJournals = async () => {
      try {
        const response = await axios.get(
          `${import.meta.env.VITE_API_BASE_URL}/api/journal`
        );
        if (response.data && response.data.journals) {
          setJournals(response.data);
          
          // Get the first year from the journals
          const years = Object.values(response.data.journals).map(item => item.year);
          const firstYear = Math.max(...years); // Get the most recent year
          
          if (firstYear) {
            setSelectedYear(firstYear);
            
            // Get the first month for this year
            const firstMonthData = Object.values(response.data.journals).find(
              item => item.year === firstYear
            );
            if (firstMonthData) {
              setSelectedMonth(firstMonthData.month);
            }
          }

          // Initialize loading states for all images
          const newLoadingStates = {};
          
          // For latest journals
          if (response.data.latest) {
            response.data.latest.forEach(journal => {
              if (journal.image) {
                newLoadingStates[journal.image] = true;
                const img = new Image();
                img.src = journal.image;
                img.onload = () => handleImageLoad(journal.image);
                img.onerror = () => handleImageLoad(journal.image);
              }
            });
          }
          
          // For all other journals
          Object.values(response.data.journals).forEach(yearData => {
            if (yearData.journal) {
              yearData.journal.forEach(journal => {
                if (journal.image) {
                  newLoadingStates[journal.image] = true;
                  const img = new Image();
                  img.src = journal.image;
                  img.onload = () => handleImageLoad(journal.image);
                  img.onerror = () => handleImageLoad(journal.image);
                }
              });
            }
          });
          
          setImageLoadingStates(newLoadingStates);
        }
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch journals');
        setLoading(false);
      }
    };

    fetchJournals();
  }, []);

  const handleYearClick = (year) => {
    setSelectedYear(year);
    const firstMonthOfYear = Object.values(journals.journals).find(
      (item) => item.year === year
    )?.month;
    setSelectedMonth(firstMonthOfYear);
  };

  const handleMonthClick = (month) => {
    setSelectedMonth(month);
  };

  const handleReadMore = (pdfUrl) => {
    // Check if user is logged in
    const storedUserId = Cookies.get('Userid');

    if (storedUserId) {
      // If logged in, open the PDF
      window.open(pdfUrl, '_blank');
    } else {
      // If not logged in, show alert
      toast.info('Please login to access the journal');
    }
  };

  const truncateContent = (content) => {
    if (!content) return '';
    const words = content.split(' ');
    if (words.length > 100) {
      return words.slice(0, 30).join(' ') + '...';
    }
    return content;
  };

  // Get unique years
  const uniqueYears = journals.journals
    ? [
        ...new Set(Object.values(journals.journals).map((item) => item.year)),
      ].sort((a, b) => b - a)
    : [];

  // Get months for selected year
  const filteredMonths = journals.journals
    ? Object.values(journals.journals)
        .filter((item) => item.year === selectedYear)
        .sort((a, b) => {
          const months = [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec',
          ];
          return months.indexOf(a.month) - months.indexOf(b.month);
        })
    : [];

  // Get journals for selected month
  const journalsToDisplay =
    selectedYear && selectedMonth
      ? Object.values(journals.journals).find(
          (item) => item.year === selectedYear && item.month === selectedMonth
        )?.journal || []
      : [];

  // Add image loading handler
  const handleImageLoad = (imageId) => {
    setImageLoadingStates((prev) => ({
      ...prev,
      [imageId]: false,
    }));
  };

  if (loading) {
    return (
      <div className="w-full h-[50vh] flex items-center justify-center">
        Loading...
      </div>
    );
  }

  if (error) {
    return (
      <div className="w-full h-[50vh] flex items-center justify-center text-red-500">
        {error}
      </div>
    );
  }

  return (
    <div id="section6" className="w-full h-auto mb-20">
      <div className="header">
        <h2 className="text-2xl font-bold bg-blue-500 h-[8vh] w-full backdrop-filter bg-opacity-40 backdrop-blur-sm text-black-900 items-center flex justify-center">
          JOURNAL
        </h2>
      </div>

      <div className="TopLatest w-full mt-10 px-5 md:px-10">
        <div className="head w-full">
          <h1 className="text-[32px] font-bold">LATEST</h1>
        </div>
        {journals.latest.map((journal) => (
          <div
            key={journal.id}
            className="journal w-full h-auto xl:h-auto bg-[#D6E6FF] rounded-[23px] p-5 mt-5 flex md:flex-row flex-col justify-start items-start gap-7"
          >
            {/* <div className="relative leftImg bg-[#D6E6FF] w-full md:w-[50rem] h-[40vh] xl:h-[10rem] xl:w-[50rem] rounded-2xl overflow-hidden">
              {imageLoadingStates[journal.image] && (
                <div className="absolute inset-0 flex items-center justify-center bg-gray-100">
                  <div className="w-8 h-8 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
                </div>
              )}
              <div
                className={`w-full h-full bg-cover transition-opacity duration-300 ${
                  imageLoadingStates[journal.image]
                    ? 'opacity-0'
                    : 'opacity-100'
                }`}
                style={{ backgroundImage: `url(${journal.image})` }}
              >
                <img
                  // src={journal.image}
                  alt={journal.title}
                  className="hidden"
                  // onLoad={() => handleImageLoad(journal.image)}
                  onError={() => handleImageLoad(journal.image)}
                />
              </div>
            </div> */}
            <div className="rightTxt">
              <h1 className="text-[14px] md:text-[20px]">
                <p>
                  {truncateContent(journal.description)}{' '}
                  <span
                    onClick={() => handleReadMore(journal.pdfUrl)}
                    className="text-red-500 cursor-pointer"
                  >
                    Read More
                  </span>
                </p>
              </h1>
            </div>
          </div>
        ))}

        <div className="years mt-5 flex gap-4 justify-center flex-wrap">
          {uniqueYears.map((year) => (
            <button
              key={year}
              onClick={() => handleYearClick(year)}
              className={`transition-all duration-300 ease-in-out hover:bg-blue-300 hover:text-black tex w-[35vw] md:w-[10vw] 
              h-[6vh] md:h-[5vh] ${
                selectedYear === year
                  ? 'bg-[#008CFF] text-white'
                  : 'bg-[#D6E6FF] text-zinc-900'
              } rounded-full text-[4vw] md:text-[1vw] items-center h-[3vh] md:h-[5vh]`}
            >
              {year}
            </button>
          ))}
        </div>

        {selectedYear && (
          <div className="months mt-5 flex gap-4 justify-center flex-wrap">
            {filteredMonths.map((item) => (
              <button
                key={item.month}
                onClick={() => handleMonthClick(item.month)}
                className={`transition-all duration-300 ease-in-out hover:bg-blue-300 hover:text-black 
              w-[35vw] md:w-[10vw] 
              h-[6vh] md:h-[5vh]
              ${selectedMonth === item.month ? 'bg-[#008CFF] text-white' : 'bg-[#D6E6FF] text-zinc-900'} 
              rounded-full 
              text-[4vw] md:text-[1vw] 
              items-center`}
              >
                {item.month}
              </button>
            ))}
          </div>
        )}

        {journalsToDisplay.length > 0 && (
          <div className="journals mt-5 flex flex-col gap-5">
            {journalsToDisplay.map((journal) => (
              <div
                key={journal.id}
                className="journal w-full h-auto xl:h-[30vh] bg-[#D6E6FF] rounded-[23px] p-5 flex md:flex-row flex-col justify-center items-center gap-7"
              >
                <div className="relative leftImg bg-[#D6E6FF] w-full md:w-[50rem] h-[40vh] xl:h-[10rem] xl:w-[50rem] rounded-2xl overflow-hidden">
                  {imageLoadingStates[journal.image] && (
                    <div className="absolute inset-0 flex items-center justify-center bg-gray-100">
                      <div className="w-8 h-8 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
                    </div>
                  )}
                  <div
                    className={`w-full h-full bg-cover transition-opacity duration-300 ${
                      imageLoadingStates[journal.image]
                        ? 'opacity-0'
                        : 'opacity-100'
                    }`}
                    style={{ backgroundImage: `url(${journal.image})` }}
                  >
                    <img
                      src={journal.image}
                      alt={journal.title}
                      className="hidden"
                      onLoad={() => handleImageLoad(journal.image)}
                      onError={() => handleImageLoad(journal.image)}
                    />
                  </div>
                </div>
                <div className="rightTxt md:w-[250%]">
                  <h1 className="text-[14px] md:text-[20px]">
                    <p>
                      {truncateContent(journal.description)}{' '}
                      <span
                        onClick={() => handleReadMore(journal.pdfUrl)}
                        className="text-red-500 cursor-pointer"
                      >
                        Read More
                      </span>
                    </p>
                  </h1>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      <ToastContainer />
    </div>
  );
};

export default Journal;