import React, { useState, useEffect } from 'react';
import axios from 'axios';

const AdminJournal = () => {
  const [journals, setJournals] = useState({ latest: [], journals: {} });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [selectedJournal, setSelectedJournal] = useState(null);
  const [cloudinaryLoaded, setCloudinaryLoaded] = useState(false);
  const [formData, setFormData] = useState({
    month: '',
    year: new Date().getFullYear(),
    journalNo: 1,
    description: '',
    pdfUrl: '',
    image: '',
    section: 'regular'
  });

  // Load Cloudinary script
  useEffect(() => {
    if (!window.cloudinary) {
      const script = document.createElement('script');
      script.src = 'https://upload-widget.cloudinary.com/global/all.js';
      script.async = true;
      
      script.onload = () => {
        console.log('Cloudinary script loaded');
        setCloudinaryLoaded(true);
      };

      script.onerror = () => {
        console.error('Failed to load Cloudinary script');
        setMessage('Error loading upload widget');
      };

      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    } else {
      setCloudinaryLoaded(true);
    }
  }, []);

  // Fetch all journals
  const fetchJournals = async () => {
    try {
      const response = await axios.get(`${import.meta.env.VITE_API_BASE_URL}/api/journal`);
      setJournals(response.data);
  
    } catch (error) {
      setMessage('Error fetching journals: ' + error.message);
    }
  };

  useEffect(() => {
    fetchJournals();
  }, []);

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  // Handle image upload using Cloudinary widget
  const handleImageUpload = () => {
    if (!cloudinaryLoaded) {
      setMessage('Image upload widget is still loading...');
      return;
    }

    const widget = window.cloudinary.createUploadWidget(
      {
        cloudName: import.meta.env.VITE_CLOUDINARY_CLOUD_NAME,
        uploadPreset: import.meta.env.VITE_CLOUDINARY_UPLOAD_PRESET,
        folder: 'journals/images',
        sources: ['local'],
        multiple: false,
        maxFileSize: 5000000, // 5MB
        clientAllowedFormats: ['jpg', 'jpeg', 'png'],
      },
      (error, result) => {
        if (error) {
          setMessage('Error uploading image: ' + error.message);
          return;
        }
        
        if (result && result.event === "success") {
          setFormData(prev => ({
            ...prev,
            image: result.info.secure_url
          }));
          setMessage('Image uploaded successfully!');
        }
      }
    );
    widget.open();
  };

  // Cloudinary Widget for PDF
  const handlePdfUpload = () => {
    if (!cloudinaryLoaded) {
      setMessage('PDF upload widget is still loading...');
      return;
    }

    const widget = window.cloudinary.createUploadWidget(
      {
        cloudName: import.meta.env.VITE_CLOUDINARY_CLOUD_NAME,
        uploadPreset: import.meta.env.VITE_CLOUDINARY_UPLOAD_PRESET,
        folder: 'journals/pdfs',
        sources: ['local'],
        multiple: false,
        resourceType: 'image',
        clientAllowedFormats: ['pdf'],
        maxFileSize: 10000000, // 10MB
      },
      (error, result) => {
        if (error) {
          setMessage('Error uploading PDF: ' + error.message);
          return;
        }

        if (result && result.event === "success") {
          // Transform the URL to make it viewable
          const publicId = result.info.public_id;
          const pdfUrl = `https://res.cloudinary.com/${import.meta.env.VITE_CLOUDINARY_CLOUD_NAME}/image/upload/${publicId}.pdf`;
          setFormData(prev => ({
            ...prev,
            pdfUrl: pdfUrl
          }));
          setMessage('PDF uploaded successfully!');
        }
      }
    );
    widget.open();
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.pdfUrl || !formData.image) {
      setMessage('Please upload both PDF and image files');
      return;
    }
  
    setLoading(true);
    try {
      const journalData = {
        month: formData.month,
        year: formData.year,
        journalNo: formData.journalNo,
        description: formData.description,
        pdfUrl: formData.pdfUrl,
        image: formData.image,
        section: formData.section
      };
  
      if (selectedJournal) {
        const response = await axios.put(
          `${import.meta.env.VITE_API_BASE_URL}/api/journal/${selectedJournal.id}`,
          journalData
        );
        
        // Check if the update was successful
        if (response.status === 200) {
          setMessage('Journal updated successfully!');
        } else {
          // This is a fallback, but the catch block would likely handle most error cases
          setMessage('Update failed. Please try again.');
          return;
        }
      } else {
        await axios.post(
          `${import.meta.env.VITE_API_BASE_URL}/api/journal`,
          journalData
        );
        setMessage('Journal created successfully!');
      }
      
      fetchJournals();
      resetForm();
    } catch (error) {
      // More specific error handling
      if (selectedJournal) {
        setMessage(`Failed to update journal: ${error.response?.data?.message || error.message}`);
      } else {
        setMessage(`Failed to create journal: ${error.response?.data?.message || error.message}`);
      }
    } finally {
      setLoading(false);
    }
  };

  // Reset form
  const resetForm = () => {
    setSelectedJournal(null);
    setFormData({
      month: '',
      year: new Date().getFullYear(),
      journalNo: 1,
      description: '',
      pdfUrl: '',
      image: '',
      section: 'regular'
    });
  };

  // Add this helper function at the top of your component
  const getPdfUrl = (url) => {
    if (!url) return '#';
    // If it's already a PDF URL, return as is
    if (url.endsWith('.pdf')) return url;
    
    // If it's an image URL, convert to PDF URL
    return url.replace(/\.[^/.]+$/, '.pdf');
  };

  // Handle journal deletion
  const handleDelete = async (id) => {
    if (!window.confirm('Are you sure you want to delete this journal?')) {
      return;
    }

    setLoading(true);
    try {
      await axios.delete(`${import.meta.env.VITE_API_BASE_URL}/api/journal/${id}`);
      setMessage('Journal deleted successfully!');
      fetchJournals(); // Refresh the list
    } catch (error) {
      setMessage('Error deleting journal: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  // Handle journal edit
  const handleEdit = (journal) => {
    let month = '';
    let year = new Date().getFullYear();
    let section = 'latest';

    // First, check in regular journals
    const regularJournalDetails = Object.values(journals.journals)
      .find(yearData => 
        yearData.journal.some(j => j.id === journal.id)
      );

    if (regularJournalDetails) {
      month = regularJournalDetails.month;
      year = regularJournalDetails.year;
      section = 'regular';
    }

    setSelectedJournal(journal);
    setFormData({
      month: month,
      year: year,
      journalNo: journal.journalNo || 1,
      description: journal.description || '',
      pdfUrl: journal.pdfUrl || '',
      image: journal.image || '',
      section: section
    });
    
    // Scroll to the form
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <div className="p-6">
      {message && (
      <div 
        className={`mb-4 p-3 rounded ${
          message.includes('Error') 
            ? 'bg-red-100 text-red-700' 
            : 'bg-green-100 text-green-700'
        }`}
      >
        {message}
      </div>
    )}
      <h2 className="text-2xl font-bold mb-6">Journal Management</h2>

      {/* Journal Form */}
      <div className="bg-white p-6 rounded-lg shadow-md mb-6">
        <h3 className="text-xl mb-4">{selectedJournal ? 'Edit Journal' : 'Create New Journal'}</h3>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <select
              name="section"
              value={formData.section}
              onChange={handleInputChange}
              className="border p-2 rounded"
            >
              <option value="regular">Regular</option>
              <option value="latest">Latest</option>
            </select>

            {formData.section === 'regular' && (
              <>
                <select
                  name="month"
                  value={formData.month}
                  onChange={handleInputChange}
                  className="border p-2 rounded"
                  required={formData.section === 'regular'}
                >
                  <option value="">Select Month</option>
                  {['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'].map(month => (
                    <option key={month} value={month}>{month}</option>
                  ))}
                </select>

                <input
                  type="number"
                  name="year"
                  value={formData.year}
                  onChange={handleInputChange}
                  placeholder="Year"
                  className="border p-2 rounded"
                  required={formData.section === 'regular'}
                />

                <input
                  type="number"
                  name="journalNo"
                  value={formData.journalNo}
                  onChange={handleInputChange}
                  placeholder="Journal Number"
                  className="border p-2 rounded"
                  required={formData.section === 'regular'}
                />
              </>
            )}
          </div>

          <textarea
            name="description"
            value={formData.description}
            onChange={handleInputChange}
            placeholder="Description"
            className="w-full border p-2 rounded h-32"
            required
          />

          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">
              Cover Image
            </label>
            <div className="flex items-center space-x-4">
              <button
                type="button"
                onClick={handleImageUpload}
                disabled={!cloudinaryLoaded}
                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
              >
                Upload Image
              </button>
              {formData.image && (
                <div className="flex items-center space-x-2">
                  <span className="text-green-600">✓ Image Uploaded</span>
                  <img src={formData.image} alt="Preview" className="h-10 w-10 object-cover rounded" />
                </div>
              )}
            </div>
          </div>

          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">
              PDF Document
            </label>
            <div className="flex items-center space-x-4">
              <button
                type="button"
                onClick={handlePdfUpload}
                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
              >
                Upload PDF
              </button>
              {formData.pdfUrl && (
                <div className="flex items-center space-x-2">
                  <span className="text-green-600">✓ PDF Uploaded</span>
                  <a
                    href={getPdfUrl(formData.pdfUrl)}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-500 hover:underline"
                  >
                    View PDF
                  </a>
                </div>
              )}
            </div>
          </div>

          <div className="flex gap-2">
            <button
              type="submit"
              disabled={loading}
              className="bg-blue-500 text-white px-6 py-2 rounded hover:bg-blue-600"
            >
              {loading ? 'Saving...' : (selectedJournal ? 'Update' : 'Create')}
            </button>
            {selectedJournal && (
              <button
                type="button"
                onClick={resetForm}
                className="bg-gray-500 text-white px-6 py-2 rounded hover:bg-gray-600"
              >
                Cancel
              </button>
            )}
          </div>
        </form>
      </div>

      {/* Journals List */}
      <div className="bg-white p-6 rounded-lg shadow-md">
        <h3 className="text-xl mb-4">Existing Journals</h3>
        
        {/* Latest Journal */}
        {journals.latest.length > 0 && (
          <div className="mb-6">
            <h4 className="font-bold mb-2">Latest Journal</h4>
            {journals.latest.map(journal => (
              <div key={journal.id} className="border p-4 rounded mb-2 flex justify-between items-center">
                <div>
                  <h5 className="font-bold">{journal.description}</h5>
                  <div className="flex items-center space-x-2 mt-2">
                    <img src={journal.image} alt="Cover" className="h-10 w-10 object-cover rounded" />
                    <a
                      href={getPdfUrl(journal.pdfUrl)}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-500 hover:underline"
                    >
                      View PDF
                    </a>
                  </div>
                </div>
                <div className="flex gap-2">
                <button
  onClick={() => {
    console.log('Editing journal:', journal);
    handleEdit(journal);
  }}
  className="bg-yellow-500 text-white px-3 py-1 rounded"
>
  Edit
</button>
                  <button
                    onClick={() => handleDelete(journal.id)}
                    disabled={loading}
                    className={`bg-red-500 text-white px-3 py-1 rounded ${
                      loading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-red-600'
                    }`}
                  >
                    Delete
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}

        {/* Regular Journals */}
        {Object.entries(journals.journals).map(([key, monthData]) => (
          <div key={key} className="mb-6">
            <h4 className="font-bold mb-2">{`${monthData.month} ${monthData.year}`}</h4>
            {monthData.journal.map(journal => (
              <div key={journal.id} className="border p-4 rounded mb-2 flex justify-between items-center">
                <div>
                  <h5 className="font-bold">{journal.description}</h5>
                  <div className="flex items-center space-x-2 mt-2">
                    <img src={journal.image} alt="Cover" className="h-10 w-10 object-cover rounded" />
                    <a
                      href={getPdfUrl(journal.pdfUrl)}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-500 hover:underline"
                    >
                      View PDF
                    </a>
                  </div>
                </div>
                <div className="flex gap-2">
                  <button
                    onClick={() => handleEdit(journal)}
                    className="bg-yellow-500 text-white px-3 py-1 rounded"
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => handleDelete(journal.id)}
                    disabled={loading}
                    className={`bg-red-500 text-white px-3 py-1 rounded ${
                      loading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-red-600'
                    }`}
                  >
                    Delete
                  </button>
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>

      {message && (
        <div className={`mt-4 p-3 rounded ${
          message.includes('Error') 
            ? 'bg-red-100 text-red-700' 
            : 'bg-green-100 text-green-700'
        }`}>
          {message}
        </div>
      )}
    </div>
  );
};

export default AdminJournal;