import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaExternalLinkAlt, FaLink, FaSearch, FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const ExternalLinks = () => {
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [expandedGroup, setExpandedGroup] = useState(null);
  const [groupLinks, setGroupLinks] = useState({});
  const [links, setLinks] = useState([]);
  const [imageStates, setImageStates] = useState({});
  const [groupLoadingStates, setGroupLoadingStates] = useState({});

  // Fetch all groups
  const fetchGroups = async (search = '') => {
    try {
      setLoading(true);
      const response = await axios.get(`${import.meta.env.VITE_API_BASE_URL}/api/external-links`, {
        params: { search }
      });
      setGroups(Array.isArray(response.data) ? response.data : []);
    } catch (error) {
      console.error('Error fetching external links:', error);
      setGroups([]);
    } finally {
      setLoading(false);
    }
  };

  // Fetch links for a specific group
  const fetchGroupLinks = async (groupId) => {
    try {
      const response = await axios.get(`${import.meta.env.VITE_API_BASE_URL}/api/external-links/${groupId}`);
      setGroupLinks(prev => ({
        ...prev,
        [groupId]: response.data.links
      }));
    } catch (error) {
      console.error('Error fetching group links:', error);
    }
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchGroups(searchTerm);
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const handleGroupClick = async (groupId) => {
    if (expandedGroup === groupId) {
      setExpandedGroup(null);
    } else {
      setExpandedGroup(groupId);
      if (!groupLinks[groupId]) {
        // Set loading state for this group
        setGroupLoadingStates(prev => ({
          ...prev,
          [groupId]: true
        }));
        
        await fetchGroupLinks(groupId);
        
        // Remove loading state after fetch
        setGroupLoadingStates(prev => ({
          ...prev,
          [groupId]: false
        }));
      }
    }
  };

  useEffect(() => {
    const fetchLinks = async () => {
      try {
        const response = await axios.get(`${import.meta.env.VITE_API_BASE_URL}/api/external-links`);
        setLinks(response.data);
        // Initialize loading states for each link's image
        const initialStates = {};
        response.data.forEach(link => {
          initialStates[link._id] = { loading: true };
        });
        setImageStates(initialStates);
      } catch (error) {
        console.error('Error fetching links:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchLinks();
  }, []);

  const handleImageLoad = (linkId) => {
    setImageStates(prev => ({
      ...prev,
      [linkId]: { loading: false }
    }));
  };

  // Loading state with skeleton UI
  if (loading) {
    return (
      <div className="min-h-screen p-8 bg-gray-50">
        <div className="max-w-6xl mx-auto">
          <div className="h-8 w-48 bg-gray-200 rounded animate-pulse mb-8"></div>
          <div className="space-y-4">
            {[1, 2, 3].map((n) => (
              <div key={n} className="bg-white p-6 rounded-lg shadow-md">
                <div className="h-6 w-32 bg-gray-200 rounded animate-pulse"></div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen p-8 bg-gray-50">
      <div className="max-w-6xl mx-auto">
        <div className="flex items-center justify-between mb-8">
        <div className="w-full flex flex-col md:flex-row md:justify-between items-center gap-4">
  {/* Title */}
  <div className="flex items-center gap-2">
    <h1 className="text-2xl md:text-3xl font-bold text-gray-800 text-center md:text-left">
      External Links
    </h1>
    <FaLink className="text-[#008CFF] text-xl md:text-2xl" />
  </div>
  
  {/* Search Bar */}
  <div className="relative w-full md:w-[20rem]">
    <input
      type="text"
      value={searchTerm}
      onChange={(e) => setSearchTerm(e.target.value)}
      placeholder="Search groups..."
      className="w-full pl-10 pr-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-[#008CFF] focus:border-transparent"
    />
    <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
  </div>
</div>

        </div>
        
        {groups.length === 0 ? (
          <div className="flex flex-col items-center justify-center bg-white rounded-lg shadow-md p-8">
            <FaLink className="text-gray-300 text-5xl mb-4" />
            <p className="text-gray-500 text-lg">No external links available at the moment.</p>
            <p className="text-gray-400 text-sm mt-2">Please check back later.</p>
          </div>
        ) : (
          <div className="space-y-4">
            {groups.map((group) => (
              <div 
                key={group._id} 
                className="bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300"
              >
                <button
                  onClick={() => handleGroupClick(group._id)}
                  className="w-full p-6 flex items-center justify-between text-left"
                >
                  <h2 className="text-xl font-semibold text-[#008CFF]">
                    {group.name}
                  </h2>
                  {expandedGroup === group._id ? (
                    <FaChevronUp className="text-gray-400" />
                  ) : (
                    <FaChevronDown className="text-gray-400" />
                  )}
                </button>
                
                {expandedGroup === group._id && (
                  <div className="px-6 pb-6 space-y-3 border-t pt-4">
                    {groupLoadingStates[group._id] ? (
                      // Loading skeleton
                      <div className="space-y-3">
                        {[1, 2, 3].map((n) => (
                          <div key={n} className="animate-pulse flex items-center justify-between">
                            <div className="h-4 bg-gray-200 rounded w-2/3"></div>
                            <div className="h-4 bg-gray-200 rounded w-4"></div>
                          </div>
                        ))}
                      </div>
                    ) : groupLinks[group._id]?.length > 0 ? (
                      groupLinks[group._id].map((link) => (
                        <a
                          key={link._id}
                          href={link.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="flex items-center justify-between group text-gray-700 hover:text-[#008CFF] transition-all duration-200 p-2 rounded-md hover:bg-gray-50"
                        >
                          <span>{link.label}</span>
                          <FaExternalLinkAlt className="text-sm opacity-0 group-hover:opacity-100 transition-opacity" />
                        </a>
                      ))
                    ) : (
                      <p className="text-gray-500 text-sm text-center">
                        No links available in this group
                      </p>
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>
        )}

        {groups.length > 0 && (
          <div className="mt-8 text-center text-gray-500 text-sm">
            <p>These links are provided for reference purposes. We are not responsible for the content of external websites.</p>
          </div>
        )}
      </div>
      <div className="div w-full flex justify-center items-center mt-5">
<Link 
            to="/" 
            className="inline-block bg-blue-500 text-white px-6 py-3 rounded-full hover:bg-blue-600 transition-colors duration-300"
          >
            Back to Home
          </Link>
</div>
    </div>
  );
};

export default ExternalLinks;