import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { X } from 'lucide-react';
import { setAuthData, isUserLoggedIn } from '../utilis/auth';  // Import your setAuthData utility function and isUserLoggedIn function
import Cookies from 'js-cookie'; // Import the js-cookie library
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const LoginModal = ({ isOpen, onClose }) => {
  const navigate = useNavigate();
  const [emailOrMemId, setEmailOrMemId] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [reveal, setReveal] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    setIsLoggedIn(isUserLoggedIn());
  }, []);

  const handleReveal = () => {
    setReveal(prev => !prev);
  };

  const handleLogout = () => {
    Cookies.remove('AuthToken');
    Cookies.remove('Userid');
    setIsLoggedIn(false);
    window.location.reload();
  };

  if (isLoggedIn) {
    return (
      <button
        onClick={handleLogout}
        className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded transition-colors duration-200"
      >
        Logout
      </button>
    );
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let requestBody = { password };
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (emailRegex.test(emailOrMemId)) {
        requestBody.email = emailOrMemId;
      } else {
        requestBody.membershipId = emailOrMemId;
      }

      const response = await axios.post(
        `${import.meta.env.VITE_API_BASE_URL}/api/users/login`,
        requestBody
      );

      const { token, membershipId, Userid } = response.data;
      setAuthData(token, membershipId);
      Cookies.set('AuthToken', token, { expires: 7 });
      Cookies.set('Userid', Userid, { expires: 7 });
      setIsLoggedIn(true);
      alert('Welcome back!');
      setErrorMessage('');
      onClose();
      window.location.reload(); // Refresh to update login state
    } catch (error) {
      console.error(error);
      setErrorMessage(error.response?.data?.message || 'Login failed. Please try again.');
    }
  };

  if (!isOpen) return null;

  const HandleForget = () => {
    toast.info(`Contact Admin for the login details !`);
  }

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      {/* Backdrop */}
      <div
        className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm"
        onClick={onClose}
      />

      {/* Modal */}
      <div className="bg-white rounded-3xl w-[90%] max-w-md p-8 z-50 relative shadow-xl">
        {/* Close button */}
        <button
          onClick={onClose}
          className="absolute right-4 top-4 text-gray-500 hover:text-gray-700 transition-colors"
        >
          <X size={24} />
        </button>

        {/* Header */}
        <div className="text-center mb-6">
          <h2 className="text-2xl font-bold text-gray-900 mb-2">Welcome Back</h2>
          <p className="text-gray-600">Please enter your details to sign in</p>
        </div>

        {/* Error message */}
        {errorMessage && (
          <p className="text-red-500 text-sm text-center mb-4">{errorMessage}</p>
        )}

        {/* Form */}
        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label
              htmlFor="emailOrMemId"
              className="block text-sm font-medium text-gray-700 mb-2" 
            >
              Membership ID
            </label>
            <input
              id="emailOrMemId"
              type="text"
              value={emailOrMemId}
              onChange={(e) => setEmailOrMemId(e.target.value)}
              className="w-full px-4 py-3 rounded-xl border border-gray-300 focus:outline-none focus:ring-2 focus:ring-[#0098FF] focus:border-transparent transition-all"
              placeholder="AB-12345"
              required
            />
          </div>

          <div>
            <label
              htmlFor="password"
              className="block text-sm font-medium text-gray-700 mb-2"
            >
              Password
            </label>
            <div className="div flex justify-center items-center gap-3">
            <input
              id="password"
              type={reveal ? "text" : "password"} 
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full px-4 py-3 rounded-xl border border-gray-300 focus:outline-none focus:ring-2 focus:ring-[#0098FF] focus:border-transparent transition-all"
              placeholder="Enter your password"
              required
            />
            <div>
               <div onClick={handleReveal} className="div">
                            {reveal ? <FaEye /> : <FaEyeSlash />}
                            </div>
                            </div>
            </div>
          </div>

          {/* Forgot password link */}
          <div className="text-right">
            <a
            onClick={HandleForget}
              
              className="text-[#0098FF] hover:text-[#0072ff] text-sm font-medium transition-colors"
            >
              Forgot password?
            </a>
          </div>

          {/* Submit button */}
          <button
            type="submit"
            className="w-full py-3 px-4 bg-gradient-to-br from-[#00c6ff] to-[#0072ff] text-white font-bold rounded-xl hover:shadow-lg hover:translate-y-[-2px] active:translate-y-[1px] transition-all duration-200"
          >
            Sign in
          </button>
        </form>
      </div>
      <ToastContainer />

    </div>
  );
};

export default LoginModal;