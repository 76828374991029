import React, { useState, useEffect } from 'react';
import axios from 'axios';

// Utility function to format the date and time
const formatDateTime = (timestamp) => {
  const date = new Date(timestamp);

  const day = date.getDate();
  const month = date.toLocaleString('default', { month: 'long' });
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, '0');

  // Add suffix for day (st, nd, rd, th)
  const suffix = day % 10 === 1 && day !== 11 ? 'st' :
                 day % 10 === 2 && day !== 12 ? 'nd' :
                 day % 10 === 3 && day !== 13 ? 'rd' : 'th';

  // Format: "11th June 2024, 14:05"
  return `${day}${suffix} ${month} ${year}, ${hours}:${minutes}`;
};

const formatFieldName = (field) => {
  return field
    .replace(/([A-Z])/g, ' $1')
    .replace(/^./, str => str.toUpperCase());
};

const NotificationDetails = ({ notification }) => {
  if (!notification || !notification.details) {
    return null;
  }

  if (notification.type === 'PASSWORD_CHANGE') {
    return (
      <p className="text-gray-600">Password was changed</p>
    );
  }

  if (notification.type === 'DETAILS_UPDATE' && notification.details.changes) {
    // Filter out memId from updatedFields if it exists
    const updatedFields = notification.details.updatedFields?.filter(field => field !== 'memId');
    
    if (!updatedFields || updatedFields.length === 0) return null;

    return (
      <div className="mt-2 text-sm">
        <p className="font-medium">Changed fields:</p>
        <ul className="list-disc list-inside space-y-1 mt-1">
          {updatedFields.map(field => {
            const changes = notification.details.changes[field];
            if (!changes) return null;
            
            return (
              <li key={field} className="text-gray-600">
                {formatFieldName(field)}:
                <span className="text-red-500 line-through ml-1">
                  {changes.from || 'empty'}
                </span>
                <span className="text-green-500 ml-1">
                  → {changes.to || 'empty'}
                </span>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }

  return null;
};

const Notifications = () => {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const fetchNotifications = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${import.meta.env.VITE_API_BASE_URL}/api/notifications`
      );
      setNotifications(response.data || []);
    } catch (err) {
      setError('Unable to load notifications. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNotifications();
    const interval = setInterval(fetchNotifications, 60000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="p-4">
      <h2 className="text-2xl text-black font-bold mb-4">Notifications</h2>
      
      {loading && (
        <div className="flex justify-center">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
        </div>
      )}

      {error && (
        <div className="bg-red-100 text-red-700 p-3 rounded mb-4">
          {error}
        </div>
      )}

      <div className="space-y-4">
        {notifications.map((notification) => (
          <div
            key={notification._id}
            className="relative p-4 rounded-lg shadow bg-white"
          >
            {/* Top-right date and time */}
            <span className="absolute top-2 right-2 text-sm text-gray-500">
              {formatDateTime(notification.details?.timestamp)}
            </span>

            <div className="flex justify-between items-start">
              <div className="w-full">
                <h3 className="font-semibold text-lg">
                  {notification.type === 'PASSWORD_CHANGE' ? '🔑 Password Changed' : '📝 Details Updated'}
                </h3>
                <p className="text-gray-600">{notification.message}</p>
                {notification.details?.membershipId && (
                  <p className="text-sm text-gray-500">
                    Member ID: {notification.details.membershipId}
                  </p>
                )}
                <NotificationDetails notification={notification} />
              </div>
            </div>
          </div>
        ))}

        {notifications.length === 0 && !loading && (
          <div className="text-center text-gray-500 py-8">
            No notifications to display
          </div>
        )}
      </div>
    </div>
  );
};

export default Notifications;
