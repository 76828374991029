import React from 'react';

const members = [
  {
    id: "MEM-001",
    name: "John Doe",
    contactNo: "555-123-4567",
    unit: "Unit 101",
    address: "Building A, 123 Main St",
    profession: "Software Engineer",
    email: "john.doe@email.com",
    yearJoined: 2020
  },
  {
    id: "MEM-002",
    name: "Jane Smith",
    contactNo: "555-234-5678",
    unit: "Unit 205",
    address: "Building B, 456 Oak Ave",
    profession: "Architect",
    email: "jane.smith@email.com",
    yearJoined: 2021
  },
  {
    id: "MEM-003",
    name: "Michael Johnson",
    contactNo: "555-345-6789",
    unit: "Unit 308",
    address: "Building C, 789 Pine Rd",
    profession: "Doctor",
    email: "michael.j@email.com",
    yearJoined: 2019
  },
  {
    id: "MEM-004",
    name: "Sarah Williams",
    contactNo: "555-456-7890",
    unit: "Unit 402",
    address: "Building D, 321 Elm St",
    profession: "Teacher",
    email: "sarah.w@email.com",
    yearJoined: 2022
  }
];

const MemberTable = () => {
  return (
    <div className="h-screen w-full p-6 bg-gray-50">
      <div className="w-full overflow-x-auto shadow-md rounded-lg">
        <table className="w-full text-sm text-left whitespace-nowrap">
          <thead className="text-white bg-blue-600">
            <tr>
              <th className="px-6 py-4 whitespace-nowrap">Membership ID</th>
              <th className="px-6 py-4 whitespace-nowrap">Name</th>
              <th className="px-6 py-4 whitespace-nowrap">Contact No</th>
              <th className="px-6 py-4 whitespace-nowrap">Unit</th>
              <th className="px-6 py-4 whitespace-nowrap">Address</th>
              <th className="px-6 py-4 whitespace-nowrap">Profession</th>
              <th className="px-6 py-4 whitespace-nowrap">Email</th>
              <th className="px-6 py-4 whitespace-nowrap">Year Joined</th>
            </tr>
          </thead>
          <tbody>
            {members.map((member) => (
              <tr 
                key={member.id} 
                className="bg-white border-b hover:bg-gray-50"
              >
                <td className="px-6 py-4 whitespace-nowrap">
                  <span className="font-mono text-blue-600 font-semibold bg-blue-50 px-2 py-1 rounded">
                    {member.id}
                  </span>
                </td>
                <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                  {member.name}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <span className="font-mono text-green-600 font-semibold bg-green-50 px-2 py-1 rounded">
                    {member.contactNo}
                  </span>
                </td>
                <td className="px-6 py-4 text-gray-600 whitespace-nowrap">
                  {member.unit}
                </td>
                <td className="px-6 py-4 text-gray-600 whitespace-nowrap">
                  {member.address}
                </td>
                <td className="px-6 py-4 text-gray-600 whitespace-nowrap">
                  {member.profession}
                </td>
                <td className="px-6 py-4 text-gray-600 whitespace-nowrap">
                  {member.email}
                </td>
                <td className="px-6 py-4 text-gray-600 whitespace-nowrap">
                  {member.yearJoined}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MemberTable;