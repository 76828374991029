import React, { useState, useEffect } from 'react';
import axios from 'axios';

const AdminPhotogallery = () => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [photos, setPhotos] = useState([]);
  const [selectedYear, setSelectedYear] = useState('');
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editingPhoto, setEditingPhoto] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    eventName: '',
    programName: '',
    year: new Date().getFullYear(),
    mainImage: '',
    additionalImages: [],
  });

  const currentYear = new Date().getFullYear();
  const years = Array.from(
    { length: currentYear - 2019 },
    (_, i) => currentYear - i
  );

  useEffect(() => {
    const loadCloudinaryScript = () => {
      const script = document.createElement('script');
      script.src = 'https://upload-widget.cloudinary.com/global/all.js';
      script.async = true;
      document.body.appendChild(script);
      return script;
    };

    const script = loadCloudinaryScript();
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    fetchPhotos();
  }, [selectedYear]);

  const fetchPhotos = async () => {
    try {
      setLoading(true);
      const url = selectedYear
        ? `${import.meta.env.VITE_API_BASE_URL}/api/photos?year=${selectedYear}`
        : `${import.meta.env.VITE_API_BASE_URL}/api/photos`;
      const response = await axios.get(url);
      setPhotos(response.data.photos || []);
    } catch (error) {
      console.error('Error fetching photos:', error);
      setMessage(
        'Error fetching photos: ' +
          (error.response?.data?.message || error.message)
      );
    } finally {
      setLoading(false);
    }
  };

  const handleUpload = (isEditMode = false) => {
    if (typeof window.cloudinary === 'undefined') {
      setMessage('Upload widget is still loading. Please try again in a moment.');
      return;
    }

    const widget = window.cloudinary.createUploadWidget(
      {
        cloudName: import.meta.env.VITE_CLOUDINARY_CLOUD_NAME,
        uploadPreset: import.meta.env.VITE_CLOUDINARY_UPLOAD_PRESET,
        folder: 'photo-gallery',
        maxFiles: 1,
        sources: ['local', 'url', 'camera'],
        clientAllowedFormats: ['jpg', 'jpeg', 'png'],
        resourceType: 'image',
      },
      (error, result) => {
        if (error) {
          setMessage('Upload failed: ' + error.message);
          return;
        }

        if (result.event === 'success') {
          setFormData((prev) => ({
            ...prev,
            mainImage: result.info.secure_url,
          }));
          setMessage(
            isEditMode
              ? 'Photo updated. Click Update to save changes.'
              : 'Main photo selected. Click Submit Event to save.'
          );
        }
      }
    );

    widget.open();
  };

  const handleUploadAdditionalImages = () => {
    const widget = window.cloudinary.createUploadWidget(
      {
        cloudName: import.meta.env.VITE_CLOUDINARY_CLOUD_NAME,
        uploadPreset: import.meta.env.VITE_CLOUDINARY_UPLOAD_PRESET,
        folder: 'photo-gallery',
        maxFiles: 10,
        sources: ['local', 'url', 'camera'],
        clientAllowedFormats: ['jpg', 'jpeg', 'png'],
        resourceType: 'image',
        multiple: true,
      },
      (error, result) => {
        if (error) {
          setMessage('Upload failed: ' + error.message);
          return;
        }

        if (result.event === 'success') {
          setFormData((prev) => ({
            ...prev,
            additionalImages: [...prev.additionalImages, result.info.secure_url],
          }));
        }
      }
    );
    widget.open();
  };

  const handleEdit = (photo) => {
    setEditingPhoto(photo);
    setFormData({
      name: photo.name,
      description: photo.description || '',
      eventName: photo.eventName,
      programName: photo.programName || '',
      year: photo.year,
      mainImage: photo.imageUrl,
      additionalImages: photo.additionalImages || [],
    });
    setIsEditModalOpen(true);
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    if (!formData.name || !formData.eventName || !formData.year || !formData.mainImage) {
      setMessage('Please fill in all required fields');
      return;
    }

    try {
      setLoading(true);
      const updateData = {
        name: formData.name,
        description: formData.description,
        eventName: formData.eventName,
        programName: formData.programName,
        year: formData.year,
        imageUrl: formData.mainImage,
        additionalImages: formData.additionalImages || [],
      };

      const response = await axios.put(
        `${import.meta.env.VITE_API_BASE_URL}/api/photos/${editingPhoto._id}`,
        updateData
      );

      setPhotos(photos.map(photo => 
        photo._id === editingPhoto._id ? response.data : photo
      ));
      setMessage('Photo updated successfully!');
      setIsEditModalOpen(false);
      setEditingPhoto(null);
      resetForm();
    } catch (error) {
      setMessage(
        'Error updating photo: ' +
          (error.response?.data?.message || error.message)
      );
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.name || !formData.eventName || !formData.year || !formData.mainImage) {
      setMessage('Please fill in all required fields and upload a main photo');
      return;
    }
  
    try {
      setLoading(true);
      const photoData = {
        photos: [
          {
            name: formData.name,
            description: formData.description,
            eventName: formData.eventName,
            programName: formData.programName,
            year: formData.year,
            imageUrl: formData.mainImage,
            additionalImages: formData.additionalImages, // This should now work correctly
          },
        ],
      };
  
      const response = await axios.post(
        `${import.meta.env.VITE_API_BASE_URL}/api/photos/event`,
        photoData,
        {
          params: {
            eventName: formData.eventName,
            year: formData.year
          }
        }
      );
  
      setPhotos((prev) => [...prev, ...response.data]);
      setMessage('Event photos uploaded successfully!');
      resetForm();
    } catch (error) {
      setMessage(
        'Error uploading photos: ' +
        (error.response?.data?.message || error.message)
      );
    } finally {
      setLoading(false);
    }
  };



  const handleDeletePhoto = async (photoId) => {
    if (!window.confirm('Are you sure you want to delete this photo?')) {
      return;
    }

    try {
      setLoading(true);
      const response = await axios.delete(
        `${import.meta.env.VITE_API_BASE_URL}/api/photos/${photoId}`
      );

      if (response.status === 200) {
        setPhotos(prevPhotos => prevPhotos.filter(photo => photo._id !== photoId));
        setMessage('Photo deleted successfully!');
      }
    } catch (error) {
      console.error('Error deleting photo:', error);
      setMessage(
        'Error deleting photo: ' +
        (error.response?.data?.message || error.message)
      );
    } finally {
      setLoading(false);
    }
  };

  const resetForm = () => {
    setFormData({
      name: '',
      description: '',
      eventName: '',
      programName: '',
      year: new Date().getFullYear(),
      mainImage: '',
      additionalImages: [],
    });
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    requestAnimationFrame(() => {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    });
  };

  const EditModal = () => (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg w-full max-w-2xl max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-xl font-bold">Edit Photo</h3>
          <button
            onClick={() => {
              setIsEditModalOpen(false);
              setEditingPhoto(null);
              resetForm();
            }}
            className="text-gray-500 hover:text-gray-700"
          >
            ×
          </button>
        </div>

        <form onSubmit={handleUpdate} className="space-y-4">
          <div className="space-y-4">
            <input
              type="text"
              name="eventName"
              placeholder="Event Name *"
              defaultValue={formData.eventName}
              onBlur={handleFormChange}
              className="w-full p-2 border rounded"
              required
            />
            <input
              type="text"
              name="programName"
              placeholder="Program Name"
              defaultValue={formData.programName}
              onBlur={handleFormChange}
              className="w-full p-2 border rounded"
            />
            <input
              type="text"
              name="name"
              placeholder="Name *"
              defaultValue={formData.name}
              onBlur={handleFormChange}
              className="w-full p-2 border rounded"
              required
            />
            <textarea
              name="description"
              placeholder="Description"
              defaultValue={formData.description}
              onBlur={handleFormChange}
              className="w-full p-2 border rounded"
            />
            <input
              type="number"
              name="year"
              placeholder="Year *"
              defaultValue={formData.year}
              onBlur={handleFormChange}
              className="w-full p-2 border rounded"
              required
            />
          </div>

          {formData.mainImage && (
            <div className="relative w-full h-48">
              <img
                src={formData.mainImage}
                alt="Main thumbnail"
                className="w-full h-full object-cover rounded"
              />
              <button
                type="button"
                onClick={() => setFormData({ ...formData, mainImage: '' })}
                className="absolute top-2 right-2 bg-red-500 text-white p-1 rounded-full"
              >
                ×
              </button>
            </div>
          )}

          {formData.additionalImages.length > 0 && (
            <div className="grid grid-cols-4 gap-2">
              {formData.additionalImages.map((url, index) => (
                <div key={index} className="relative">
                  <img
                    src={url}
                    alt={`Additional ${index + 1}`}
                    className="w-full h-24 object-cover rounded"
                  />
                  <button
                    type="button"
                    onClick={() => {
                      const newImages = formData.additionalImages.filter(
                        (_, i) => i !== index
                      );
                      setFormData({ ...formData, additionalImages: newImages });
                    }}
                    className="absolute top-1 right-1 bg-red-500 text-white p-1 rounded-full text-sm"
                  >
                    ×
                  </button>
                </div>
              ))}
            </div>
          )}

          <div className="flex gap-2">
            <button
              type="button"
              onClick={() => handleUpload(true)}
              disabled={loading}
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors"
            >
              Change Main Photo
            </button>
            <button
              type="button"
              onClick={handleUploadAdditionalImages}
              disabled={loading}
              className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition-colors"
            >
              Add Additional Photos
            </button>
          </div>

          <button
            type="submit"
            disabled={loading || !formData.mainImage}
            className="w-full bg-purple-500 text-white px-4 py-2 rounded hover:bg-purple-600 transition-colors disabled:bg-gray-400"
          >
            {loading ? 'Updating...' : 'Update Photo'}
          </button>
        </form>
      </div>
    </div>
  );

  if (loading && photos.length === 0) {
    return <div className="p-6">Loading...</div>;
  }

  return (
    <div className="p-6">
      <h2 className="text-2xl font-bold mb-4">Photo Gallery Management</h2>

      {message && (
        <div
          className={`p-4 mb-4 rounded ${
            message.includes('Error') ? 'bg-red-100 text-red-700' : 'bg-green-100 text-green-700'
          }`}
        >
          {message}
        </div>
      )}

      <div className="mb-4">
        <label className="block mb-2">Filter by Year:</label>
        <select
          value={selectedYear}
          onChange={(e) => setSelectedYear(e.target.value)}
          className="p-2 border rounded"
        >
          <option value="">All Years</option>
          {years.map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
      </div>

      <div className="border p-4 rounded-lg mb-6">
        <h3 className="text-xl mb-3">Add New Event Photos</h3>
        <form onSubmit={handleSubmit} className="space-y-4">
          <input
            type="text"
            name="eventName"
            placeholder="Event Name *"
            value={formData.eventName}
            onChange={handleFormChange}
            className="w-full p-2 border rounded"
            required
          />
          <input
            type="text"
            name="programName"
            placeholder="Program Name"
            value={formData.programName}
            onChange={handleFormChange}
            className="w-full p-2 border rounded"
          />
          <input
            type="text"
            name="name"
            placeholder="Name *"
            value={formData.name}
            onChange={handleFormChange}
            className="w-full p-2 border rounded"
            required
          />
          <textarea
            name="description"
            placeholder="Description"
            value={formData.description}
            onChange={handleFormChange}
            className="w-full p-2 border rounded"
          />
          <input
            type="number"
            name="year"
            placeholder="Year *"
            value={formData.year}
            onChange={handleFormChange}
            className="w-full p-2 border rounded"
            required
          />

          {formData.mainImage && (
            <div className="relative w-full h-48">
              <img
                src={formData.mainImage}
                alt="Main thumbnail"
                className="w-full h-full object-cover rounded"
              />
              <button
                type="button"
                onClick={() => setFormData({ ...formData, mainImage: '' })}
                className="absolute top-2 right-2 bg-red-500 text-white p-1 rounded-full"
              >
                ×
              </button>
            </div>
          )}

          {formData.additionalImages.length > 0 && (
            <div className="grid grid-cols-4 gap-2">
              {formData.additionalImages.map((url, index) => (
                <div key={index} className="relative">
                  <img
                    src={url}
                    alt={`Additional ${index + 1}`}
                    className="w-full h-24 object-cover rounded"
                  />
                  <button
                    type="button"
                    onClick={() => {
                      const newImages = formData.additionalImages.filter(
                        (_, i) => i !== index
                      );
                      setFormData({ ...formData, additionalImages: newImages });
                    }}
                    className="absolute top-1 right-1 bg-red-500 text-white p-1 rounded-full text-sm"
                  >
                    ×
                  </button>
                </div>
              ))}
            </div>
          )}

          <div className="flex gap-2">
            <button
              type="button"
              onClick={() => handleUpload(false)}
              disabled={loading}
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors"
            >
              Upload Main Photo
            </button>
            <button
              type="button"
              onClick={handleUploadAdditionalImages}
              disabled={loading}
              className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition-colors"
            >
              Add Additional Photos
            </button>
          </div>

          <button
            type="submit"
            disabled={loading || !formData.mainImage}
            className="w-full bg-purple-500 text-white px-4 py-2 rounded hover:bg-purple-600 transition-colors disabled:bg-gray-400"
          >
            {loading ? 'Uploading...' : 'Submit Event'}
          </button>
        </form>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-6">
        {photos.map((photo) => (
          <div
            key={photo._id}
            className="border rounded-lg p-4 bg-white shadow"
          >
            <img
              src={photo.imageUrl}
              alt={photo.name}
              className="w-full h-48 object-cover rounded-lg mb-3"
            />
            <h3 className="font-semibold mb-2">{photo.name}</h3>
            <p className="text-gray-600 text-sm mb-2">
              {photo.eventName} - {photo.year}
            </p>
            {photo.programName && (
              <p className="text-gray-600 text-sm mb-2">
                Program: {photo.programName}
              </p>
            )}
            {photo.description && (
              <p className="text-gray-600 text-sm mb-2">
                {photo.description}
              </p>
            )}

            {photo.additionalImages && photo.additionalImages.length > 0 && (
              <div className="grid grid-cols-4 gap-2 mb-3">
                {photo.additionalImages.map((url, index) => (
                  <img
                    key={index}
                    src={url}
                    alt={`Additional ${index + 1}`}
                    className="w-full h-16 object-cover rounded"
                  />
                ))}
              </div>
            )}

            <div className="flex justify-end gap-2">
              <button
                onClick={() => handleEdit(photo)}
                className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600"
              >
                Edit
              </button>
              <button
                onClick={() => handleDeletePhoto(photo._id)}
                className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600"
              >
                Delete Photo
              </button>
            </div>
          </div>
        ))}
      </div>

      {isEditModalOpen && <EditModal />}

      {loading && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-4 rounded-lg">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500 mx-auto"></div>
            <p className="mt-2">Processing...</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminPhotogallery;