import React, { useEffect, useState } from 'react';
import axios from 'axios';

const MembershipHandle = () => {
  const [categories, setCategories] = useState([]);
  const [newCategory, setNewCategory] = useState({
    category: '',
    abbreviation: '',
  });
  const [editingId, setEditingId] = useState(null);
  const [editValue, setEditValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await axios.get(
        `${import.meta.env.VITE_API_BASE_URL}/api/membership/categories`
      );
      setCategories(response.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
      setMessage('Error loading categories');
    }
  };

  const handleChange = (e) => {
    setNewCategory((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage('');

    try {
      await axios.post(
        `${import.meta.env.VITE_API_BASE_URL}/api/membership/add-abbreviation`,
        newCategory
      );

      setMessage('Category added successfully!');
      setNewCategory({ category: '', abbreviation: '' });
      fetchCategories();
    } catch (error) {
      console.error('Error saving data:', error);
      setMessage(error.response?.data?.message || 'Error saving data');
    } finally {
      setLoading(false);
    }
  };

  const startEditing = (category) => {
    setEditingId(category._id);
    setEditValue(category.abbreviation);
  };

  const cancelEditing = () => {
    setEditingId(null);
    setEditValue('');
  };

  const handleUpdateAbbreviation = async (id) => {
    if (!editValue.trim()) return;

    try {
      await axios.put(
        `${import.meta.env.VITE_API_BASE_URL}/api/membership/categories/${id}`,
        { abbreviation: editValue }
      );
      setMessage('Category updated successfully!');
      setEditingId(null);
      fetchCategories();
    } catch (error) {
      console.error('Error updating abbreviation:', error);
      setMessage('Error updating abbreviation');
    }
  };

  const handleDelete = async (id, categoryName) => {
    if (window.confirm('Are you sure you want to delete this category?')) {
      try {
        await axios.delete(
          `${import.meta.env.VITE_API_BASE_URL}/api/membership/delete`,
          {
            data: {
              category: categoryName,
              abbreviation: '', // Sending empty abbreviation to delete entire category
            },
          }
        );
        setMessage('Category deleted successfully!');
        fetchCategories();
      } catch (error) {
        console.error('Error deleting category:', error);
        setMessage('Error deleting category');
      }
    }
  };

  return (
    <div className="w-full h-screen flex flex-col items-center mt-10">
      <div className="w-full p-3 md:w-[60%]">
        <h2 className="text-2xl font-bold mb-6">Membership Categories</h2>
        {message && (
          <div
            className={`p-3 mb-4 rounded ${
              message.includes('Error')
                ? 'bg-red-100 text-red-700'
                : 'bg-green-100 text-green-700'
            }`}
          >
            {message}
          </div>
        )}

        <form onSubmit={handleSubmit} className="mb-8">
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Category Name
              </label>
              <input
                type="text"
                name="category"
                value={newCategory.category}
                onChange={handleChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Abbreviation
              </label>
              <input
                type="text"
                name="abbreviation"
                value={newCategory.abbreviation}
                onChange={handleChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                required
              />
            </div>
            <button
              type="submit"
              disabled={loading}
              className={`w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white ${
                loading ? 'bg-gray-400' : 'bg-blue-600 hover:bg-blue-700'
              }`}
            >
              {loading ? 'Adding...' : 'Add Category'}
            </button>
          </div>
        </form>

        <div className="mt-6">
          <h3 className="text-lg font-medium mb-4">Existing Categories</h3>
          <div className="space-y-4">
            {categories.map((category) => (
              <div key={category._id} className="p-4 border rounded-lg">
                <div className="flex items-center justify-between mb-2">
                  <p className="font-medium">{category.category}</p>
                  <div className="flex gap-2">
                    {editingId !== category._id ? (
                      <button
                        onClick={() => startEditing(category)}
                        className="px-3 py-1 text-sm bg-blue-100 text-blue-600 rounded hover:bg-blue-200"
                      >
                        Edit
                      </button>
                    ) : (
                      <>
                        <button
                          onClick={() => handleUpdateAbbreviation(category._id)}
                          className="px-3 py-1 text-sm bg-green-100 text-green-600 rounded hover:bg-green-200"
                        >
                          Save
                        </button>
                        <button
                          onClick={cancelEditing}
                          className="px-3 py-1 text-sm bg-gray-100 text-gray-600 rounded hover:bg-gray-200"
                        >
                          Cancel
                        </button>
                      </>
                    )}
                    <button
                      onClick={() =>
                        handleDelete(category._id, category.category)
                      }
                      className="px-3 py-1 text-sm bg-red-100 text-red-600 rounded hover:bg-red-200"
                    >
                      Delete
                    </button>
                  </div>
                </div>
                <div className="mt-1">
                  {editingId === category._id ? (
                    <input
                      type="text"
                      value={editValue}
                      onChange={(e) => setEditValue(e.target.value)}
                      className="w-full p-1 text-sm border rounded focus:border-blue-500 focus:ring-blue-500"
                      placeholder="Enter new abbreviation"
                    />
                  ) : (
                    <p className="text-sm text-gray-500">
                      {category.abbreviation}
                    </p>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MembershipHandle;