import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

const AboutUs = () => {
  const [images, setImages] = useState({
    topImage: '',
    middleImage: '',
    bottomImage: '',
  });

  const [imageStates, setImageStates] = useState({
    topImage: { loading: true },
    middleImage: { loading: true },
    bottomImage: { loading: true }
  });

  // Pre-define image dimensions
  const imagePlaceholders = {
    width: '100%',
    height: '300px',
    background: '#f0f0f0'
  };

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await axios.get(`${import.meta.env.VITE_API_BASE_URL}/api/aboutus`);

        if (response.data) {
          setImages({
            topImage: response.data.topImage || '',
            middleImage: response.data.middleImage || '',
            bottomImage: response.data.bottomImage || '',
          });
        }
      } catch (error) {
        console.error('Error fetching images:', error);
      }
    };

    fetchImages();

    document.title = 'THE AERONAUTICAL SOCIETY OF INDIA - Kochi Branch';
    
    const metaTags = [
      { name: 'description', content: 'Learn about the Kochi Chapter of the Aeronautical Society of India, its mission, vision, and various outreach programs for the youth in the field of aeronautics.' },
      { name: 'keywords', content: 'Aerospace, Aeronautics, Kochi, Engineering, Aeronautical Society of India, Workshops, Outreach, Seminars' },
      { property: 'og:title', content: 'About Us - Kochi Chapter Aeronautical Society' },
      { property: 'og:description', content: 'Learn about the Kochi Chapter of the Aeronautical Society of India, its mission, vision, and various outreach programs for the youth in the field of aeronautics.' },
      { property: 'og:image', content: images.topImage || 'default-image.jpg' },
      { property: 'og:url', content: window.location.href },
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:title', content: 'About Us - Kochi Chapter Aeronautical Society' },
      { name: 'twitter:description', content: 'Learn about the Kochi Chapter of the Aeronautical Society of India, its mission, vision, and various outreach programs for the youth in the field of aeronautics.' },
      { name: 'twitter:image', content: images.topImage || 'default-image.jpg' }
    ];

    metaTags.forEach(tag => {
      let metaTag = document.querySelector(`meta[${tag.property ? 'property' : 'name'}="${tag.property || tag.name}"]`);
      if (!metaTag) {
        metaTag = document.createElement('meta');
        if (tag.property) {
          metaTag.setAttribute('property', tag.property);
        } else {
          metaTag.setAttribute('name', tag.name);
        }
        document.head.appendChild(metaTag);
      }
      metaTag.setAttribute('content', tag.content);
    });

    return () => {
      document.title = 'THE AERONAUTICAL SOCIETY OF INDIA - Kochi Branch';
    };
  }, [images.topImage]);

  const handleImageLoad = (imageKey) => {
    setImageStates(prev => ({
      ...prev,
      [imageKey]: { loading: false }
    }));
  };

  return (
    <section id="section2" className="w-full h-auto">
  
      <div className="max-w-screen-xxl mx-auto shadow-lg overflow-hidden">
        <h2 className="text-2xl font-bold bg-blue-500 h-[8vh] w-full backdrop-filter bg-opacity-40 backdrop-blur-sm text-black-900 items-center flex justify-center">
          ABOUT US
        </h2>

        <div className="md:flex">
          {/* Left Column: Text content */}
          <div className="flex-1 p-6 lg:p-10">
            <p className="text-gray-700 text-[1.4rem] text-justify leading-relaxed mb-6">
              The Kochi chapter of the Aeronautical Society of India was conceptualized in late 1996 and formally inaugurated on 29 Jun 1997. This vibrant chapter conducts Annual National seminars, School/College outreach programs to increase awareness and generate interest in aeronautics among the young minds, Aero quiz competitions, monthly lecture series by eminent personalities in the field of aviation, and various workshops to promote Aerospace and Aeronautical Engineering.
            </p>
            <Link to="/executive">
              <button className="bg-blue-500 text-white font-semibold py-2 px-4 rounded-full hover:bg-blue-600 transition-colors">
                EXECUTIVE COUNCIL →
              </button>
            </Link>
          </div>

          {/* Right Column: Image content */}
          <div className="flex flex-col w-full md:w-[40vw] p-6 lg:p-10 gap-1">
            {/* First Row: Top and Middle images */}
            <div className="w-full flex gap-1 group">
              {/* Top Image */}
              <div className="relative w-[50%] h-[20vh]">
                {imageStates.topImage.loading && (
                  <div className="absolute inset-0 flex items-center justify-center bg-gray-100 rounded-lg">
                    <div className="w-8 h-8 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
                  </div>
                )}
                <img
                  src={images.topImage}
                  alt="Event 1"
                  className={`w-full h-full object-cover rounded-lg transition-opacity duration-300 ${
                    imageStates.topImage.loading ? 'opacity-0' : 'opacity-100'
                  }`}
                  onLoad={() => handleImageLoad('topImage')}
                  loading="lazy"
                  decoding="async"
                />
              </div>

              {/* Middle Image */}
              <div className="relative w-[50%] h-[20vh]">
                {imageStates.middleImage.loading && (
                  <div className="absolute inset-0 flex items-center justify-center bg-gray-100 rounded-lg">
                    <div className="w-8 h-8 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
                  </div>
                )}
                <img
                  src={images.middleImage}
                  alt="Event 2"
                  className={`w-full h-full object-cover rounded-lg transition-opacity duration-300 ${
                    imageStates.middleImage.loading ? 'opacity-0' : 'opacity-100'
                  }`}
                  onLoad={() => handleImageLoad('middleImage')}
                />
              </div>
            </div>

            {/* Second Row: Bottom image */}
            <div className="relative w-full h-[20vh]">
              {imageStates.bottomImage.loading && (
                <div className="absolute inset-0 flex items-center justify-center bg-gray-100 rounded-lg">
                  <div className="w-8 h-8 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
                </div>
              )}
              <img
                src={images.bottomImage}
                alt="Event 3"
                className={`w-full h-full object-cover rounded-lg transition-opacity duration-300 ${
                  imageStates.bottomImage.loading ? 'opacity-0' : 'opacity-100'
                }`}
                onLoad={() => handleImageLoad('bottomImage')}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;