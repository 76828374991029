import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie'; // Import the js-cookie library

const Member_ship = () => {
  const [applyOnlineLink, setApplyOnlineLink] = useState(
    'https://www.aerosocietyindia.co.in/Home/MembershipForms'
  );
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const navigate = useNavigate();
  const [chart, setChart] = useState([]);

  // Check if Userid exists in cookies when component mounts
  useEffect(() => {
    const storedUserId = Cookies.get('Userid');
    setIsUserLoggedIn(!!storedUserId);
  }, []);

  const checkUpdates = () => {
    // Retrieve the Userid from the cookie
    const storedUserId = Cookies.get('Userid');

    if (storedUserId) {
      // Navigate to the member modification page
      navigate('/MemberModification');
    } else {
      // Show alert if user is not logged in
      toast.info('Please login to edit your information');
    }
  };

  const handleDownloadPDF = () => {
    const pdfUrl = '/42.pdf';

    const link = document.createElement('a');
    link.href = pdfUrl;
    link.download = 'AeSI-membership-form.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleApplyOnline = () => {
    window.open(applyOnlineLink, '_blank');
  };

  // Fetch the current membership link
  useEffect(() => {
    const fetchMembershipLink = async () => {
      try {
        const response = await axios.get(
          `${import.meta.env.VITE_API_BASE_URL}/api/landing-page/membership-link`
        );

        setApplyOnlineLink(response.data.membershipLink);
      } catch (error) {
        console.error('Error fetching membership link:', error);
      }
    };

    fetchMembershipLink();
  }, []);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(
          `${import.meta.env.VITE_API_BASE_URL}/api/membership/categories`
        );
        const categoriesData = response.data.map((cat) => ({
          name: cat.category,
          designation: cat.abbreviation,
        }));
        setChart(categoriesData);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();
  }, []);

  return (
    <div id="section5" className="h-auto w-full pb-10">
      <div className="header">
        <h2 className="text-2xl font-bold bg-blue-500 h-[8vh] w-full backdrop-filter bg-opacity-40 backdrop-blur-sm text-black-900 items-center flex justify-center">
          MEMBERSHIP
        </h2>
      </div>

      <div className="top w-full h-auto relative flex flex-col xl:flex-row justify-end items-center">
        <div className="imgleft relative transition-transform transform hover:scale-105 duration-700">
          <img
            className="left-20 -top-20"
            src="/membership-flight.webp"
            alt="Membership Flight"
          />
        </div>

        <div className="txtRight w-[98vw] text-justify 2xl:text-start 2xl:w-[70vw] text-[24px] md:text-[25px] mt-5 px-4 2xl:px-0 2xl:mt-20">
          The Society provides a platform for members to meet and discuss issues
          of mutual interest with professionals in the field of Aeronautics and
          Aerospace Sciences. The Society provides free participation in
          seminars, workshops, and conferences organized by the Society to
          promote aeronautics and aerospace sciences. Every member is entitled
          to a copy of the quarterly journal, devoted to research and technical
          papers on various facets of aeronautical sciences.
        </div>
      </div>

      <div className="bottomChart w-full mt-10">
        <div className="txtbottom">
          <h1 className="text-[22px] md:text-[26px] font-bold flex justify-center items-center px-5">
            Members have the privileges to use the following abbreviation after
            their names on election:
          </h1>
        </div>
        <div className="chart w-full px-4 md:px-20 h-auto mb-5 mt-10 flex flex-col gap-1">
          <div className="ChartHead w-full flex gap-1">
            <div className="left rounded-[10px] w-[50%] bg-gradient-to-t from-[#005499] flex justify-center items-center to-[#008CFF] h-[5vh]">
              <h1 className="font-bold text-white text-[16px] md:text-[24px]">
                CATEGORY
              </h1>
            </div>
            <div className="right rounded-[10px] w-[50%] bg-gradient-to-t from-[#005499] flex justify-center items-center to-[#008CFF] h-[5vh]">
              <h1 className="font-bold text-white text-[16px] md:text-[24px]">
                ABBREVIATION
              </h1>
            </div>
          </div>
          <div className="Discribtion flex flex-col gap-1">
            {chart.map((items, id) => (
              <div className="w-full flex gap-1" key={id}>
                <div className="left rounded-[10px] w-[50%] bg-[#62A1FF] flex justify-start items-center px-2 md:px-5 h-[5vh]">
                  <h1 className="text-black text-[13px] md:text-[20px]">
                    {items.name}
                  </h1>
                </div>
                <div className="right rounded-[10px] w-[50%] bg-[#E2E9FF] flex justify-start items-center px-2 md:px-5 h-[5vh]">
                  <h1 className="text-black text-[13px] md:text-[20px]">
                    {items.designation}
                  </h1>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="buttons w-full h-auto flex md:flex-row flex-col px-5 gap-5 justify-center items-center">
        <button
          onClick={handleApplyOnline}
          className="bg-[#008CFF] text-[16px] xl:text-[24px] w-[22rem] md:w-[22rem] xl:w-[28rem] h-[4rem] rounded-full text-white font-bold hover:bg-[#89caff] hover:text-black transition-all duration-300 ease-in-out"
        >
          APPLY ONLINE
        </button>
        <button
          onClick={handleDownloadPDF}
          className="bg-[#008CFF] text-[16px] xl:text-[24px] w-[22rem] md:w-[22rem] xl:w-[28rem] h-[4rem] rounded-full text-white font-bold hover:bg-[#89caff] hover:text-black transition-all duration-300 ease-in-out"
        >
          DOWNLOAD MEMBERSHIP FORM
        </button>
        <button
          onClick={checkUpdates}
          className={`text-[16px] xl:text-[24px] w-[22rem] md:w-[22rem] xl:w-[28rem] h-[4rem] rounded-full text-white font-bold hover:bg-[#89caff] hover:text-black transition-all duration-300 ease-in-out ${
            isUserLoggedIn
              ? 'bg-[#008CFF]' // Green color when logged in
              : 'bg-[#A1D2FF] text-[#009DFF]' // Original blue color when not logged in
          }`}
        >
          EDIT INFORMATION
        </button>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Member_ship;