import React, { useState } from 'react';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [clicked, setClicked] = useState('Home');
  
  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const click = (e) => {
    e.preventDefault();
    console.log(e.target.value);
  };

  return (
    <div className="h-auto md:h-[12vh] py-2 w-full overflow-hidden flex justify-between items-center bg-[url(/background-main.png)] bg-cover px-4 md:px-3">
      
      <div className="left overflow-hidden flex justify-center items-center gap-2 md:gap-5">
        <img className="w-[5rem] md:w-[5rem]" src="/TASOI LOGO.webp" alt="Logo" />
        <div className="txt flex flex-col leading-tight ">
          <h1 className="font-bold text-[#00067D] text-[16px] sm:text-[1.8vw] md:text-[1.6vw]">
            THE AERONAUTICAL SOCIETY OF INDIA <br /> KOCHI BRANCH
          </h1>
          <h1 className="font-bold text-[#0098FF] text-[2vw] sm:text-[1vw] ">
            Promoting Excellence in Aeronautical and Aerospace profession
          </h1>
        </div>
      </div>

      <div className="right font-medium rounded-full px-3 h-[55px] bg-white overflow-hidden bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-60 border drop-shadow-xl border-gray-100 w-auto py-2 justify-center items-center text-black hidden sm:flex gap-2 xl:gap-12 text-[10px] xl:text-[12px] 2xl:text-[15px]">
        <a href="#section1" onClick={(e) => { setClicked('Home'); scrollToSection('section1'); e.preventDefault(); }} className={`${clicked === 'Home' ? 'bg-[#008CFF] px-6 py-1 rounded-full transition-all duration-300 ease-in-out drop-shadow-xl text-white' : ''}`}>Home</a>
        <a href="#section2" onClick={(e) => { setClicked('about'); scrollToSection('section2'); e.preventDefault(); }} className={`${clicked === 'about' ? 'bg-[#008CFF] px-5 py-[.4rem] rounded-full transition-all duration-300 ease-in-out drop-shadow-xl text-white' : ''}`}>About Us</a>
        <a href="#section3" onClick={(e) => { setClicked('Activity'); scrollToSection('section3'); e.preventDefault(); }} className={`${clicked === 'Activity' ? 'bg-[#008CFF] px-5 py-[.4rem] rounded-full transition-all duration-300 ease-in-out drop-shadow-xl text-white' : ''}`}>Activities</a>
        <a href="#section4" onClick={(e) => { setClicked('Photo'); scrollToSection('section4'); e.preventDefault(); }} className={`${clicked === 'Photo' ? 'bg-[#008CFF] px-5 py-[.4rem] rounded-full transition-all duration-300 ease-in-out drop-shadow-xl text-white' : ''}`}>Photo gallery</a>
        <a href="#section5" onClick={(e) => { setClicked('Membership'); scrollToSection('section5'); e.preventDefault(); }} className={`${clicked === 'Membership' ? 'bg-[#008CFF] px-5 py-[.4rem] rounded-full transition-all duration-300 ease-in-out drop-shadow-xl text-white' : ''}`}>Membership</a>
        <a href="#section6" onClick={(e) => { setClicked('Journal'); scrollToSection('section6'); e.preventDefault(); }} className={`${clicked === 'Journal' ? 'bg-[#008CFF] px-5 py-[.4rem] rounded-full transition-all duration-300 ease-in-out drop-shadow-xl text-white' : ''}`}>Journal</a>
        <a href="#section7" onClick={(e) => { setClicked('Contact'); scrollToSection('section7'); e.preventDefault(); }} className={`${clicked === 'Contact' ? 'bg-[#008CFF] px-5 py-[.4rem] rounded-full transition-all duration-300 ease-in-out drop-shadow-xl text-white' : ''}`}>Contact Us</a>
      </div>

      <div className="toggle flex sm:hidden z-10">
        <label className="flex flex-col gap-2 w-10">
          <input className="peer hidden" type="checkbox" checked={isOpen} onChange={toggleMenu} />
          <div className={`rounded-2xl h-[3px] w-1/2 bg-white duration-500 ${isOpen ? 'rotate-[225deg] -translate-x-[12px] -translate-y-[1px]' : ''}`}></div>
          <div className={`rounded-2xl h-[3px] w-full bg-white duration-500 ${isOpen ? '-rotate-45' : ''}`}></div>
          <div className={`rounded-2xl h-[3px] w-1/2 bg-white duration-500 place-self-end ${isOpen ? 'rotate-[225deg] translate-x-[12px] translate-y-[1px]' : ''}`}></div>
        </label>
      </div>

      {/* Mobile Menu */}
      <div className={`sm:hidden z-10 bg-[#b3daff] flex left-0 flex-col items-center absolute w-full transition-transform duration-300 ${isOpen ? 'transform translate-y-[185px]' : 'transform -translate-y-[360px]'}`}>
        <a className="text-black py-2" href="#section1">Home</a>
        <a className="text-black py-2" href="#section2">About Us</a>
        <a className="text-black py-2" href="#section3">Activities</a>
        <a className="text-black py-2" href="#section4">Photo gallery</a>
        <a className="text-black py-2" href="#section5">Membership</a>
        <a className="text-black py-2" href="#section6">Journal</a>
        <a className="text-black py-2" href="#section7">Contact Us</a>
      </div>
    </div>
  );
};

export default Navbar;