import React, { useState, useEffect } from 'react';

const ImageModal = ({ imageUrl, isOpen, onClose }) => {
    if (!isOpen) return null;

    const handleModalClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };

    return (
        <div
            className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75 p-4"
            onClick={handleModalClick}
        >
            <div className="relative max-w-[90vw] max-h-[90vh]">
                <button
                    onClick={onClose}
                    className="absolute -top-4 -right-4 w-8 h-8 bg-white rounded-full flex items-center justify-center text-black hover:bg-gray-200 transition-colors"
                >
                    ✕
                </button>
                <img
                    src={imageUrl}
                    alt="Enlarged view"
                    className="max-w-full max-h-[85vh] object-contain rounded-lg"
                />
            </div>
        </div>
    );
};

const PhotoGallery = () => {
    const [years, setYears] = useState([]);
    const [photos, setPhotos] = useState({});
    const [year, setYear] = useState(null);
    const [availableEvents, setAvailableEvents] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [availablePrograms, setAvailablePrograms] = useState([]);
    const [selectedProgram, setSelectedProgram] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [imageLoadingStates, setImageLoadingStates] = useState({});
    const [expandedSchools, setExpandedSchools] = useState({});
    const [modalImage, setModalImage] = useState(null);

    const handleImageClick = (imageUrl) => {
        setModalImage(imageUrl);
    };

    const handleCloseModal = () => {
        setModalImage(null);
    };

    // Reset all filters
    const resetFilters = () => {
        setSelectedEvent(null);
        setSelectedProgram(null);
        setAvailableEvents([]);
        setAvailablePrograms([]);
    };

    // Initial fetch to get available years and select the first year
    useEffect(() => {
        const fetchPhotos = async () => {
            try {
                setLoading(true);
                setError(null);
                resetFilters(); // Reset filters when fetching new data

                const response = await fetch(`${import.meta.env.VITE_API_BASE_URL}/api/photos`);

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data = await response.json();

                if (data?.availableYears?.length) {
                    const yearsData = data.availableYears.map(year => ({
                        year,
                        id: year,
                    }));
                    setYears(yearsData);
                    // Automatically select the first year
                    handleYearChange(yearsData[0].year);
                } else {
                    setError('No available years found');
                }
            } catch (err) {
                setError(`Failed to load photos: ${err.message}`);
            } finally {
                setLoading(false);
            }
        };

        fetchPhotos();
    }, []);

    // Fetch photos for selected year
    useEffect(() => {
        if (year) {
            const fetchYearPhotos = async () => {
                try {
                    setLoading(true);
                    setError(null);
                    resetFilters(); // Reset filters when changing year

                    const response = await fetch(
                        `${import.meta.env.VITE_API_BASE_URL}/api/photos?year=${year}`
                    );

                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }

                    const data = await response.json();

                    if (data?.photos && Array.isArray(data.photos)) {
                        processPhotosData(data.photos);
                    } else {
                        setError('No photos found for selected year');
                    }
                } catch (err) {
                    setError(`Failed to load photos for selected year: ${err.message}`);
                } finally {
                    setLoading(false);
                }
            };

            fetchYearPhotos();
        }
    }, [year]);

    const processPhotosData = (photosData) => {
        if (!Array.isArray(photosData) || photosData.length === 0) {
            setPhotos({});
            setAvailableEvents([]);
            setAvailablePrograms([]);
            return;
        }

        // Group photos by event and program
        const photosByEventAndProgram = {};
        const eventsSet = new Set();
        const programsSet = new Set();

        photosData.forEach(photo => {
            if (!photo.eventName) return; // Skip photos without event

            const eventKey = photo.eventName;
            const programKey = photo.programName || 'No Program'; // Handle missing program name

            eventsSet.add(eventKey);
            programsSet.add(programKey);

            if (!photosByEventAndProgram[eventKey]) {
                photosByEventAndProgram[eventKey] = {};
            }
            if (!photosByEventAndProgram[eventKey][programKey]) {
                photosByEventAndProgram[eventKey][programKey] = [];
            }
            photosByEventAndProgram[eventKey][programKey].push(photo);
        });

        const availableEventsArray = Array.from(eventsSet);
        setPhotos(photosByEventAndProgram);
        setAvailableEvents(availableEventsArray);

        // Automatically select the first event
        if (availableEventsArray.length > 0) {
            const firstEvent = availableEventsArray[0];
            setSelectedEvent(firstEvent);

            // Check if 'No Program' is the only available program for the first event
            const programsForEvent = Object.keys(photosByEventAndProgram[firstEvent] || {});
            if (programsForEvent.length === 1 && programsForEvent[0] === 'No Program') {
                // If 'No Program' is the only program, skip the program filter
                setSelectedProgram('No Program');
            } else {
                setAvailablePrograms(programsForEvent);
                setSelectedProgram(programsForEvent[0]);
            }
        }

        // Initialize loading states for images
        const newLoadingStates = {};
        photosData.forEach(photo => {
            if (photo.imageUrl) {
                newLoadingStates[photo.imageUrl] = true;
            }
            if (Array.isArray(photo.additionalImages)) {
                photo.additionalImages.forEach(img => {
                    newLoadingStates[img] = true;
                });
            }
        });
        setImageLoadingStates(newLoadingStates);
    };

    const handleYearChange = (selectedYear) => {
        if (selectedYear !== year) {
            setYear(selectedYear);
            resetFilters(); // Reset filters when changing year
        }
    };

    const handleEventChange = (event) => {
        if (event === selectedEvent) return; // Prevent re-clicking the selected event
        setSelectedEvent(event);
        setSelectedProgram(null); // Reset program selection

        // Find available programs for this event
        const programsForEvent = Object.keys(photos[event] || {});
        setAvailablePrograms(programsForEvent);

        // Select the first available program
        if (programsForEvent.length > 0) {
            setSelectedProgram(programsForEvent[0]);
        }
    };

    const handleProgramChange = (program) => {
        setSelectedProgram(program);
    };

    const handleImageLoad = (imageUrl) => {
        setImageLoadingStates(prev => ({
            ...prev,
            [imageUrl]: false
        }));
    };

    const toggleSchoolExpansion = (schoolNo) => {
        setExpandedSchools(prev => ({
            ...prev,
            [schoolNo]: !prev[schoolNo]
        }));
    };

    if (error) {
        return (
            <div className="w-full min-h-[50vh] flex items-center justify-center">
                <div className="text-red-500 text-center">
                    <p className="text-xl font-bold mb-2">Error</p>
                    <p>{error}</p>
                </div>
            </div>
        );
    }

    return (
        <div id="section4" className="w-full  h-auto bg-gray-50">
            <ImageModal
                imageUrl={modalImage}
                isOpen={!!modalImage}
                onClose={handleCloseModal}
            />

            <div className="header">
                <h2 className="text-2xl font-bold bg-blue-500 h-[8vh] w-full backdrop-filter bg-opacity-40 backdrop-blur-sm text-black-900 items-center flex justify-center">
                    PHOTO GALLERY
                </h2>
            </div>

            {loading && (
                <div className="flex justify-center items-center h-40">
                    <div className="w-8 h-8 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
                </div>
            )}

            {!loading && (
                <div className="container mx-auto px-4 py-8">
                    {/* Year Selection */}
                    {years.length > 0 && (
                        <div className="years w-full flex items-center justify-center gap-5 flex-wrap mb-8">
                            {years.map((item) => (
                                <button
                                    key={item.id}
                                    onClick={() => handleYearChange(item.year)}
                                    className={`transition-all font-semibold duration-300 ease-in-out hover:bg-blue-300 hover:text-black px-6
                                        rounded-full text-lg sm:text-xl py-2 
                                        ${year === item.year ? 'bg-blue-500 text-white' : 'bg-blue-100 text-zinc-900'}`}
                                >
                                    {item.year}
                                </button>
                            ))}
                        </div>
                    )}

                    {/* Event Selection */}
                    {availableEvents.length > 0 && (
                        <div className="events w-full flex items-center justify-center gap-5 flex-wrap mb-8">
                            {availableEvents.map((event, id) => (
                                <button
                                    key={id}
                                    onClick={() => handleEventChange(event)}
                                    className={`transition-all duration-300 ease-in-out hover:bg-blue-300 hover:text-black px-6
                                        rounded-full text-base sm:text-lg py-2
                                        ${selectedEvent === event ? 'bg-blue-500 text-white' : 'bg-blue-100 text-zinc-900'}`}
                                >
                                    {event}
                                </button>
                            ))}
                        </div>
                    )}

                    {/* Program Selection - Only show if available programs exist and if program is not 'No Program' */}
                    {selectedEvent && availablePrograms.length > 0 && selectedProgram !== 'No Program' && (
                        <div className="programs w-full flex items-center justify-center gap-5 flex-wrap mb-8">
                            {availablePrograms.map((program, id) => (
                                <button
                                    key={id}
                                    onClick={() => handleProgramChange(program)}
                                    className={`transition-all duration-300 ease-in-out hover:bg-blue-300 hover:text-black px-6
                                        rounded-full text-base sm:text-lg py-2
                                        ${selectedProgram === program ? 'bg-blue-500 text-white' : 'bg-blue-100 text-zinc-900'}`}
                                >
                                    {program}
                                </button>
                            ))}
                        </div>
                    )}

                    {/* Photo Display */}
                    {selectedEvent && (selectedProgram ? photos[selectedEvent]?.[selectedProgram] : photos[selectedEvent]) && (
                        <div className="photos w-full">
                            <div className="bg-blue-50 rounded-3xl p-6">
                                {(selectedProgram ? photos[selectedEvent][selectedProgram] : photos[selectedEvent]).map((photo, index) => (
                                    <div
                                        key={index}
                                        className="bg-white rounded-2xl mb-6 overflow-hidden shadow-lg"
                                    >
                                        <div className="flex flex-col  md:flex-row">
                                            <div className="md:w-1/3 p-4">
                                                <div
                                                    className="relative aspect-[4/3] rounded-lg overflow-hidden cursor-pointer"
                                                    onClick={() => handleImageClick(photo.imageUrl)}
                                                >
                                                    {imageLoadingStates[photo.imageUrl] && (
                                                        <div className="absolute inset-0 flex items-center justify-center bg-gray-100">
                                                            <div className="w-8 h-8 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
                                                        </div>
                                                    )}
                                                    <img
                                                        src={photo.imageUrl}
                                                        alt={photo.name}
                                                        className={`w-full h-full object-cover transition-all duration-300 hover:scale-105 $${
                                                            imageLoadingStates[photo.imageUrl] ? 'opacity-0' : 'opacity-100'
                                                        }`}
                                                        onLoad={() => handleImageLoad(photo.imageUrl)}
                                                        onError={() => handleImageLoad(photo.imageUrl)}
                                                    />
                                                </div>
                                            </div>

                                            <div className="md:w-2/3 p-6">
                                                <h3 className="text-xl md:text-2xl font-bold text-blue-600 mb-4">
                                                    {photo.name}
                                                </h3>
                                                <p className="text-gray-700 text-base md:text-lg">
                                                    {photo.description}
                                                </p>
                                            </div>
                                        </div>

                                        {Array.isArray(photo.additionalImages) && photo.additionalImages.length > 0 && (
                                            <div className="px-6 pb-6">
                                                <button
                                                    onClick={() => toggleSchoolExpansion(index)}
                                                    className="w-full flex items-center justify-center p-3 bg-blue-50 rounded-lg hover:bg-blue-100 transition-colors mb-4"
                                                >
                                                    <span className="font-semibold text-lg text-blue-700">
                                                        {expandedSchools[index] ? 'Hide Additional Images' : 'View More Images'}
                                                    </span>
                                                    <span className={`ml-2 transform transition-transform duration-300 ${expandedSchools[index] ? 'rotate-180' : ''}`}>
                                                        ▼
                                                    </span>
                                                </button>

                                                {expandedSchools[index] && (<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                                                        {photo.additionalImages.map((image, imgIndex) => (
                                                            <div
                                                                key={imgIndex}
                                                                className="relative aspect-[4/3] group overflow-hidden rounded-lg shadow-md cursor-pointer"
                                                                onClick={() => handleImageClick(image)}
                                                            >
                                                                {imageLoadingStates[image] && (
                                                                    <div className="absolute inset-0 flex items-center justify-center bg-gray-100">
                                                                        <div className="w-8 h-8 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
                                                                    </div>
                                                                )}
                                                                <img
                                                                    src={image}
                                                                    alt={`Additional image ${imgIndex + 1}`}
                                                                    className={`w-full h-full object-cover group-hover:scale-105 transition-transform duration-300 ${imageLoadingStates[image] ? 'opacity-0' : 'opacity-100'}`}
                                                                    onLoad={() => handleImageLoad(image)}
                                                                    onError={() => handleImageLoad(image)}
                                                                />
                                                            </div>
                                                        ))}

                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                ))}

                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default PhotoGallery;