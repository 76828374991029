import React, { useState } from 'react';
import axios from 'axios';
import { GoArrowRight } from 'react-icons/go';

const Contactus = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate form fields
    if (!name.trim()) {
      setStatus('Please enter your name.');
      return;
    }

    if (!email.trim()) {
      setStatus('Please enter your email.');
      return;
    }

    // Basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email.trim())) {
      setStatus('Please enter a valid email address.');
      return;
    }

    if (!subject.trim()) {
      setStatus('Please enter a subject.');
      return;
    }

    if (!message.trim()) {
      setStatus('Please enter a message.');
      return;
    }

    // Prepare form data
    const formData = {
      name: name.trim(),
      email: email.trim(),
      subject: subject.trim(),
      message: message.trim(),
    };

    try {
      const response = await axios.post(`${import.meta.env.VITE_API_BASE_URL}/api/contact/mail`, formData);
      if (response.data.success) {
        setStatus('Message sent successfully!');
        // Optional: Clear form fields after successful submission
        setName('');
        setEmail('');
        setSubject('');
        setMessage('');
      } else {
        setStatus('Failed to send message.');
      }
    } catch (error) {
      setStatus('Failed to send message.');
    }
  };

  // Rest of the component remains the same...

  return (
    <div id="section7" className='h-auto xl:h-[90vh] w-full bg-[#EFECED] pb-10'>
      <div className='w-auto h-auto'>
        <div className="header">
          <h2 className="text-2xl font-bold bg-blue-500 h-[8vh] w-full backdrop-filter bg-opacity-40 backdrop-blur-sm text-black-900 items-center flex justify-center">
            CONTACT US
          </h2>
        </div>
        <div className="main w-full h-auto bg-[url(/contactus.webp)] flex flex-col xl:flex-row justify-center items-center pt-5">
          <div className="left w-[50%] h-auto flex justify-center items-center">
            <div className="txtOnlyInXL hidden xl:flex">
              <h1 className='font-extrabold text-[42px]'>Reach Out to Us: Connect
                <span className="text-[#0066FF]"> via Email</span>
              </h1>
            </div>
            <div className="txtOnlyToXL">
              <div className="txtOnlyInXL w-full flex justify-center items-center xl:hidden">
                <h1 className='font-extrabold text-[22px] pb-10 xl:pb-0'>Reach Out to Us: Connect via Email</h1>
              </div>
            </div>
          </div>
          <div className="right w-[50%] h-full flex flex-col justify-center items-center gap-5">
            <form onSubmit={handleSubmit}>
              <div className="name">
                <h1 className='text-[15px] font-extrabold'>Your Name</h1>
                <input
                  className='w-[94vw] xl:w-[40vw] h-[5vh] rounded-[4px] pl-5'
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>

              <div className="email">
                <h1 className='text-[15px] font-extrabold'>Your Email</h1>
                <input
                  className='w-[94vw] xl:w-[40vw] h-[5vh] rounded-[4px] pl-5'
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              <div className="subject">
                <h1 className='text-[15px] font-extrabold'>Subject</h1>
                <input
                  className='w-[94vw] xl:w-[40vw] h-[5vh] rounded-[4px] pl-5'
                  type="text"
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                />
              </div>

              <div className="message">
                <h1 className='text-[15px] font-extrabold'>Your Message</h1>
                <textarea
                  className='w-[94vw] xl:w-[40vw] resize-none h-[20vh] rounded-[4px] flex justify-start items-start px-5 py-2'
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </div>

              <div className="submit w-[94vw] xl:w-[40vw] flex flex-col md:flex-row h-[9vh] mt-[1rem] md:mt-[2rem]">
  <div className="btn h-full w-[100%]">
    <button
      className="button text-[15px] h-[50px] bg-[#008CFF] w-[14rem] text-white rounded-full justify-center items-center gap-2 flex"
      type="submit"
    >
      Send <GoArrowRight />
    </button>
  </div>
</div>


              {/* Display status message */}
              {status && (
                <div className="status-message text-center mt-5">
                  <h2 className={status === 'Message sent successfully!' ? 'text-green-600' : 'text-red-600'}>{status}</h2>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contactus;