import React, { useEffect, useState, useCallback } from 'react';
import { GoArrowDown, GoArrowRight } from 'react-icons/go';
import axios from 'axios';
import LoginModal from './Login';
import { isUserLoggedIn } from '../utilis/auth';
import Cookies from 'js-cookie';

const Home = () => {
  // State management with default values
  const [images, setImages] = useState(['/home-left-bottom-main.webp']);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [marqueeText, setMarqueeText] = useState(
    'Welcome to The Aeronautical Society of India'
  );
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  // Pre-define image dimensions to prevent layout shift
  const imageDimensions = {
    backgroundLeft: { width: 500, height: 500 },
    topRight: { width: 300, height: 300 },
  };

  useEffect(() => {
    const HandleMarqueeText = async () => {
      try {
        const response = await axios.get(
          `${import.meta.env.VITE_API_BASE_URL}/api/landing-page/`
        );
        setMarqueeText(response.data.marqueeText);
      } catch (error) {
        console.log('Error updating link:', error);
      }
    };
    HandleMarqueeText();
  }, []);

  useEffect(() => {
    const fetchLandingPageData = async () => {
      try {
        const response = await axios.get(
          `${import.meta.env.VITE_API_BASE_URL}/api/landing-page/`
        );
        if (response.data && Array.isArray(response.data.images)) {
          setImages(response.data.images);
        } else if (response.data && response.data.bottomImage) {
          setImages([response.data.bottomImage]);
        }
      } catch (error) {
        console.error('Error fetching landing page data:', error);
      }
    };

    fetchLandingPageData();
  }, []);

  useEffect(() => {
    setIsLoggedIn(isUserLoggedIn());
  }, []);

  // Memoized image change callback
  const changeImage = useCallback(() => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  }, [images.length]);

  useEffect(() => {
    if (images.length > 1) {
      const interval = setInterval(() => {
        setCurrentImageIndex((prevIndex) =>
          prevIndex === images.length - 1 ? 0 : prevIndex + 1
        );
      }, 5000); // Change image every 5 seconds

      return () => clearInterval(interval);
    }
  }, [images]);

  const handleLoginClick = () => {
    if (isLoggedIn) {
      // Handle logout
      Cookies.remove('AuthToken');
      Cookies.remove('Userid');
      setIsLoggedIn(false);
      window.location.reload();
    } else {
      setIsLoginModalOpen(true);
    }
  };

  return (
    <div
      id="section1"
      className="bg-[url(/background-main.webp)] bg-cover h-auto pb-10 overflow-hidden xl:h-[120vh] 2xl:h-auto w-full md:pt-[5vh] xl:pt-[10vh] 2xl:pt-[3vh] relative flex flex-col"
    >
      <div className="bottom-image-right absolute right-0 top-[520px] md:top-[450px] md:-bottom-[230px] md:right-[250px]">
        <img
          src="/background-right-bottom-main.webp"
          alt="Background Right Bottom"
          className="md:w-[30vw]"
          width="300"
          height="300"
          loading="lazy"
          decoding="async"
        />
      </div>

      {/* Top Section */}
      <div className="top w-full h-auto xl:h-[50vh] xl:pt-14 2xl:pt-6 flex justify-between items-center md:pb-10 2xl:pb-0 flex-col relative xl:flex-row xl:px-[35px]">
        <div
          className="bg-image absolute"
          style={{
            aspectRatio: '1/1',

            width: imageDimensions.backgroundLeft.width,
            height: imageDimensions.backgroundLeft.height,
          }}
        >
          <img
            src="/background-left-top-main.webp"
            alt="Background Left Top"
            width={imageDimensions.backgroundLeft.width}
            height={imageDimensions.backgroundLeft.height}
            loading="eager"
            decoding="async"
            fetchpriority="high"
            onLoad={() => setIsImageLoaded(true)}
            style={{
              opacity: isImageLoaded ? 1 : 0,
              transition: 'opacity 0.3s',
            }}
          />
        </div>

        {/* Left Content */}
        <div
          className="left px-10 xl:w-[70vw] overflow-hidden text-justify flex flex-col gap-5 font-title"
          style={{
            minHeight: '200px',
            '--font-size-multiplier': '1',
          }}
        >
          <h1 className="md:text-[20px] mb-4">
            <span className="text-[#0098FF] md:text-[20px]">
              The Aeronautical Society of India was founded in 1948 with the
              encouragement from the late Prime Minister Pandit Jawaharlal Nehru
              as a professional body devoted to the advancement of Aeronautical
              Sciences and Engineering in India.
            </span>{' '}
            &nbsp; The objectives of the Society are to promote the advancement
            and dissemination of knowledge of the Aeronautical Sciences and
            Aircraft Engineering and the elevation of the Aeronautical
            Profession.
          </h1>

          <h1 className="md:text-[20px]">
            Some notable activities of the Aeronautical Society of India include
            yearly forums to exchange ideas and expertise in the field of
            Aeronautics as part of Annual General Meetings, periodic meetings of
            various professionals
            <span className="text-[#0098FF] md:text-[20px]">
              &nbsp; engaged in Aeronautics and related fields from
              organizations like HAL, ISRO, DRDO, Academic institutions,
              Regulatory agencies, and Civil airline operators, conduct of
              prestigious seminars and exhibitions like Aero India, formulation
              of National Aeronautical Policy, Aeronautical awareness programmes
              etc.
            </span>
          </h1>
        </div>

        {/* Right Side Image */}
        <div className="rightside">
          <img
            className="w-[18rem] rounded-[32px]"
            src="/home-top-right.webp"
            alt="Home Top Right"
            width="300"
            height="300"
          />
        </div>
      </div>

      {/* Marquee */}
      <div className="marquee xl:pt-8 2xl:py-2 xl:mt-5 2xl:mt-0">
        <div className="bottom-marquee w-full h-[2rem] xl:mt-20 2xl:mt-12 overflow-hidden whitespace-nowrap bg-white">
          {/* Duplicate the text content for a seamless loop */}
          <div className="flex animate-marquee space-x-10">
            {[...Array(5)].map((_, index) => (
              <div
                className="flex justify-start items-center space-x-10"
                key={index}
              >
                <h1 className="text-[20px] font-extrabold text-[#970000]">
                  {marqueeText}
                </h1>
                <img
                  src="/TASOI LOGO.webp"
                  className="h-4 md:h-6"
                  alt="TASOI Logo"
                />
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Bottom Section */}
      <div className="bottom w-full h-auto flex flex-col gap-5 xl:flex-row justify-between items-center px-10 mt-10">
        <div className="left">
          <div className="image w-[350px] mb-5 md:w-[378px] h-[188px] bg-[#dcebfe] rounded-[34px] relative overflow-hidden">
            {!isImageLoaded && (
              <div className="absolute inset-0 flex items-center justify-center bg-[#dcebfe] rounded-[34px]">
                <div className="w-8 h-8 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
              </div>
            )}
            <img
              className={`h-full w-full object-cover rounded-[34px] absolute top-0 left-0 transition-opacity duration-300 ${
                isImageLoaded ? 'opacity-100' : 'opacity-0'
              }`}
              src={images[currentImageIndex]}
              alt={`Slide ${currentImageIndex + 1}`}
              onLoad={() => setIsImageLoaded(true)}
              onError={(e) => {
                e.target.src = '/home-left-bottom-main.webp';
                setIsImageLoaded(true);
              }}
            />
            <div className="absolute bottom-2 left-1/2 transform -translate-x-1/2 flex gap-2">
              {images.map((_, index) => (
                <div
                  key={index}
                  className={`w-2 h-2 rounded-full ${index === currentImageIndex ? 'bg-white' : 'bg-white/50'}`}
                />
              ))}
            </div>
          </div>
        </div>

        {/* Scroll Indicator */}
        <div className="center text-red-400 hidden md:flex">
          <h1 className="flex justify-center items-center gap-2">
            Scroll Down
            <GoArrowDown />
          </h1>
        </div>

        <div className="right z-10">
          <button
            onClick={handleLoginClick}
            className="button flex justify-center items-center gap-5 md:w-[20rem] md:h-[5rem] px-7 py-3.5 mx-2 text-xl font-bold uppercase text-white bg-gradient-to-br from-[#00c6ff] to-[#0072ff] border-none rounded-full shadow-lg hover:translate-y-[-5px] hover:shadow-[0px_8px_16px_rgba(0,0,0,0.2)] active:translate-y-[2px] active:shadow-[0px_2px_5px_rgba(0,0,0,0.3)] active:bg-gradient-to-br active:from-[#0072ff] active:to-[#00c6ff] relative transition-all duration-300"
          >
            {isLoggedIn ? 'Logout' : 'Login'} <GoArrowRight />
          </button>
        </div>
      </div>

      {!isLoggedIn && (
        <LoginModal
          isOpen={isLoginModalOpen}
          onClose={() => setIsLoginModalOpen(false)}
        />
      )}
    </div>
  );
};

export default Home;
