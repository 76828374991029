import React, { useState } from "react";
import axios from "axios";
import ExcelJS from "exceljs";

const ExcelUploader = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [uploadStatus, setUploadStatus] = useState({ type: "", message: "" });
  const [userData, setUserData] = useState([]);
  const [validationErrors, setValidationErrors] = useState([]);
  const [showPreview, setShowPreview] = useState(false);

  const generatePassword = (name) => {
    if (!name) return "User@123";
    const firstName = name.replace(/\s+/g, " ").substring(0, 4).toLowerCase();
    return `${firstName}@123`;
  };

  const cleanEmailFormat = (email) => {
    if (email === null || email === undefined) return null;
    if (typeof email === 'number') return email.toString();
    if (typeof email !== 'string') return null;
    
    try {
      return email.replace(/\s+/g, "").toLowerCase();
    } catch (error) {
      return null;
    }
  };

  const cleanPhoneFormat = (phone) => {
    if (phone === null || phone === undefined) return null;
    try {
      const phoneStr = String(phone);
      return phoneStr.replace(/\D/g, '');
    } catch (error) {
      return null;
    }
  };

  const validateUserData = (data) => {
    const errors = [];
    const validUsers = [];
    
    data.forEach((user, index) => {
      try {
        const rowErrors = [];
        if (!user.memid || user.memid.toString().trim() === "") return;

        let cleanedEmail = null;
        if (user.email) {
          cleanedEmail = cleanEmailFormat(user.email);
          if (cleanedEmail && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(cleanedEmail)) {
            rowErrors.push(`Invalid email: ${user.email}`);
          }
        }

        const cleanedUser = {
          memid: String(user.memid || '').trim(),
          name: user.name ? String(user.name).trim() : null,
          email: cleanedEmail,
          contactno: cleanPhoneFormat(user.contactno),
          unit: user.unit ? String(user.unit).trim() : null,
          address: user.address ? String(user.address).trim() : null,
          profession: user.profession ? String(user.profession).trim() : null,
          yearjoined: user.yearjoined ? parseInt(String(user.yearjoined)) : null,
        };

        if (rowErrors.length > 0) {
          errors.push({ row: index + 1, errors: rowErrors });
        } else {
          validUsers.push(cleanedUser);
        }
      } catch (error) {
        errors.push({ 
          row: index + 1, 
          errors: [`Error processing row: ${error.message}`] 
        });
      }
    });
    return { errors, validUsers };
  };

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) {
      setUploadStatus({ type: "error", message: "No file selected." });
      return;
    }

    setIsLoading(true);
    setUploadStatus({ type: "", message: "" });
    setValidationErrors([]);
    setUserData([]);
    setShowPreview(false);

    try {
      const workbook = new ExcelJS.Workbook();
      const buffer = await file.arrayBuffer();
      await workbook.xlsx.load(buffer);

      const worksheet = workbook.worksheets[0];
      const rawSheetData = [];
      
      const headers = {};
      worksheet.getRow(1).eachCell((cell, colNumber) => {
        let headerValue = cell.value;
        if (typeof headerValue === 'object' && headerValue.text) {
          headerValue = headerValue.text;
        }
        headers[colNumber] = String(headerValue).toLowerCase().trim();
      });

      worksheet.eachRow((row, rowIndex) => {
        if (rowIndex === 1) return;
        
        const rowData = {};
        row.eachCell((cell, colNumber) => {
          const header = headers[colNumber];
          let value = cell.value;
          
          if (value && typeof value === 'object') {
            if (value.text) value = value.text;
            else if (value.result) value = value.result;
            else value = null;
          }
          
          rowData[header] = value;
        });
        rawSheetData.push(rowData);
      });

      const transformedData = rawSheetData.map((row) => ({
        memid: row["mem id"] || row["memid"] || null,
        name: row["name"] || null,
        email: row["email"],
        contactno: row["contact no"] || row["contactno"],
        unit: row["unit"] || null,
        address: row["address"] || null,
        profession: row["profession"] || null,
        yearjoined: row["yearjoined"] || row["year joined"] || null,
        password: generatePassword(row["name"]),
      }));

      const { errors, validUsers } = validateUserData(transformedData);

      if (errors.length > 0) {
        setValidationErrors(errors);
        setUserData(transformedData);
        setShowPreview(true);
        setUploadStatus({ 
          type: "error", 
          message: `${errors.length} validation errors found.` 
        });
      } else {
        await axios.post(
          `${import.meta.env.VITE_API_BASE_URL}/api/users/uploaduserdata`,
          validUsers
        );
        setUploadStatus({ 
          type: "success", 
          message: `Uploaded ${validUsers.length} users.` 
        });
      }
    } catch (error) {
      setUploadStatus({ 
        type: "error", 
        message: "Error processing file: " + (error.message || "Unknown error") 
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="max-w-5xl mx-auto p-6 bg-white rounded-lg shadow-md space-y-6">
      <h1 className="text-2xl font-bold text-center text-gray-700">Upload Excel File</h1>

      <div className="flex flex-col md:flex-row gap-4 justify-center items-center">
        <input
          type="file"
          accept=".xlsx, .xls"
          onChange={handleFileUpload}
          className="block w-full md:w-auto text-gray-700 file:py-2 file:px-4 file:rounded-md file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100 file:font-semibold cursor-pointer"
          disabled={isLoading}
        />
        {isLoading && <p className="text-blue-600">Processing file...</p>}
      </div>

      {uploadStatus.message && (
        <div
          className={`p-4 rounded-md ${
            uploadStatus.type === "error" ? "bg-red-50 text-red-700" : "bg-green-50 text-green-700"
          }`}
        >
          <p className="font-semibold">{uploadStatus.type === "error" ? "Error" : "Success"}</p>
          <p>{uploadStatus.message}</p>
        </div>
      )}

      {showPreview && (
        <div className="overflow-auto">
          <h2 className="text-lg font-semibold mb-2">Validation Errors</h2>
          <table className="table-auto w-full text-sm border-collapse border border-gray-200">
            <thead>
              <tr className="bg-gray-100">
                <th className="border border-gray-200 px-4 py-2">Row</th>
                <th className="border border-gray-200 px-4 py-2">Errors</th>
              </tr>
            </thead>
            <tbody>
              {validationErrors.map((error, index) => (
                <tr key={index} className="hover:bg-gray-50">
                  <td className="border border-gray-200 px-4 py-2 text-center">{error.row}</td>
                  <td className="border border-gray-200 px-4 py-2">{error.errors.join(", ")}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default ExcelUploader;