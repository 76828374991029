import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

const ExecutiveCouncil = () => {
  const [members, setMembers] = useState({
    chairman: [],
    chairmanelect: [],
    vicechairman: [],
    honsecretary: [],
    hontreasurer: [], // Fixed casing
    jointsecretary: [], // Fixed casing
    itmember: [],
    propertymember: [],
    activitymember: [],
    activitycoordinator: [],
    executivemember: []
  });

  useEffect(() => {
    const fetchMembers = async () => {
      try {
        const response = await axios.get(`${import.meta.env.VITE_API_BASE_URL}/api/executive`);
        
        // Group members by designation
        const groupedMembers = response.data.reduce((acc, member) => {
          const designation = member.designation.toLowerCase()
            .replace(/[0-9]/g, '')
            .replace(/\s+/g, '')
            .replace('-', '');
          
          if (!acc[designation]) {
            acc[designation] = [];
          }
          acc[designation].push(member);
          return acc;
        }, {});

        setMembers(groupedMembers);
      } catch (error) {
        console.error('Error fetching members:', error);
      }
    };

    fetchMembers();
  }, []);

  const capitalizeDesignation = (designation) => {
    if (!designation) return 'Position Vacant';
    
    return designation
      .replace(/[0-9]/g, '')
      .split(' ')
      .map(word => {
       
        if (word.toLowerCase() === 'it') return 'IT';
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      })
      .join(' ');
  };

  const MemberCard = ({ member }) => {
    const [imageLoading, setImageLoading] = useState(true);

    return (
      <div className="flex flex-col justify-center items-center">
        <div className="relative h-[7rem] w-[6rem] md:h-[12rem] md:w-[10rem]">
          {imageLoading && (
            <div className="absolute inset-0 flex items-center justify-center bg-gray-100 rounded-lg">
              <div className="w-6 h-6 md:w-8 md:h-8 border-3 md:border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
            </div>
          )}
          <img 
            src={member?.imageUrl || '/default-image.jpg'} 
            alt={member?.name} 
            className={`w-full h-full object-cover transition-opacity duration-300 ${
              imageLoading ? 'opacity-0' : 'opacity-100'
            }`}
            onLoad={() => setImageLoading(false)}
            onError={() => setImageLoading(false)}
          />
        </div>
        <div className="Name">
          <h1 className="text-[#000000] text-[11px] md:text-[16px]">
            {member?.name || 'Position Vacant'}
          </h1>
        </div>
        <div className="Name">
          <h1 className="text-[#ff2727] text-[11px] md:text-[16px]">
            {capitalizeDesignation(member?.designation)}
          </h1>
        </div>
      </div>
    );
  };

  return (
    <div className="bg-[url(/background-main.png)] w-full h-auto bg-cover items-center justify-center flex flex-col">
      <div className="header w-full py-5">
        <h2 className="text-2xl font-bold bg-blue-500 h-[8vh] w-full backdrop-filter bg-opacity-40 backdrop-blur-sm text-black-900 items-center flex justify-center">
          EXECUTIVE COUNCIL
        </h2>
      </div>
      <div className="BluredBG p-5 h-[95%] w-[95%] bg-blue-700 bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-20 border border-gray-100 rounded-3xl">
        <div className="top w-full h-auto flex flex-col gap-5">
          
          {/* Chairman and Chairman-Elect Row */}
          <div className="top1 flex justify-center items-center gap-[15vw]">
            <MemberCard member={members.chairman?.[0]} />
            <MemberCard member={members.chairmanelect?.[0]} />
          </div>

          {/* Vice-Chairman Row */}
          <div className="top2 flex-wrap flex justify-center items-center gap-[10vw] md:gap-[15vw]">
            {[0, 1, 2].map((index) => (
              <MemberCard key={`vice-${index}`} member={members.vicechairman?.[index]} />
            ))}
          </div>

          {/* Hon Secretary, Hon Treasurer, Joint Secretary Row */}
          <div className="top3 flex-wrap flex justify-center items-center gap-[10vw] md:gap-[5vw]">
            <MemberCard member={members.honsecretary?.[0]} />
            <MemberCard member={members.hontreasurer?.[0]} />
            <MemberCard member={members.jointsecretary?.[0]} />
          </div>

          {/* IT, Property, Activity Members Row */}
          <div className="top4 flex-wrap flex justify-center items-center gap-[10vw] md:gap-[10vw]">
            <MemberCard member={members.itmember?.[0]} />
            <MemberCard member={members.propertymember?.[0]} />
            <MemberCard member={members.activitymember?.[0]} />
            <MemberCard member={members.activitycoordinator?.[0]} />
          </div>

          {/* Executive Members Row */}
          <div className="top3 flex-wrap flex justify-center items-center gap-[10vw] md:gap-[5vw]">
            {[0, 1, 2].map((index) => (
              <MemberCard key={`executive-${index}`} member={members.executivemember?.[index]} />
            ))}
          </div>
        </div>
      </div>

      <div className="div w-full flex justify-center items-center mt-5">
        <Link 
          to="/" 
          className="inline-block bg-blue-500 text-white px-6 py-3 rounded-full hover:bg-blue-600 transition-colors duration-300"
        >
          Back to Home
        </Link>
      </div>
    </div>
  );
};

export default ExecutiveCouncil;