import React, { useState, useEffect } from 'react';
import axios from 'axios';

const AdminExecutiveCouncil = () => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [editingMember, setEditingMember] = useState(null);

  const designationCounts = {
    Chairman: 1,
    ChairmanElect: 1,
    ViceChairman: 3,
    HonSecretary: 1,  // We'll modify this to 1 but handle 3 positions later
    HonTreasurer: 1,  // New designation
    JointSecretary: 1, // New designation
    ItMember: 1,
    PropertyMember: 1,
    ActivityMember: 1,
    ActivityCoordinator: 1,
    ExecutiveMember: 3,
  };

  const generateInitialFormData = () => {
    const data = {};
    Object.entries(designationCounts).forEach(([designation, count]) => {
      data[designation] = Array(count).fill().map(() => ({ name: '', imageUrl: '', _id: null }));
    });
    return data;
  };

  const generateInitialPreviewUrls = () => {
    const urls = {};
    Object.entries(designationCounts).forEach(([designation, count]) => {
      urls[designation] = Array(count).fill('/default-image.jpg');
    });
    return urls;
  };

  const [formData, setFormData] = useState(generateInitialFormData());
  const [previewUrls, setPreviewUrls] = useState(generateInitialPreviewUrls());
  const [members, setMembers] = useState([]);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://upload-widget.cloudinary.com/global/all.js';
    script.async = true;
    document.body.appendChild(script);

    script.onerror = () => console.error('Failed to load Cloudinary widget script');

    fetchMembers();

    return () => {
      if (document.body.contains(script)) {
        document.body.removeChild(script);
      }
    };
  }, []);

  useEffect(() => {
    if (Array.isArray(members) && members.length > 0) {
      const newFormData = generateInitialFormData();
      const newPreviewUrls = generateInitialPreviewUrls();

      members.forEach(member => {
        const baseDesignation = member.designation.replace(/\d+$/, '').trim().replace(/\s+/g, '');
        const capitalizedDesignation = baseDesignation.charAt(0).toUpperCase() + baseDesignation.slice(1);
        const positionIndex = parseInt(member.designation.match(/\d+$/)?.[0] || 1) - 1;

        if (newFormData[capitalizedDesignation] && newFormData[capitalizedDesignation][positionIndex]) {
          newFormData[capitalizedDesignation][positionIndex] = {
            name: member.name,
            imageUrl: member.imageUrl,
            _id: member._id
          };
          newPreviewUrls[capitalizedDesignation][positionIndex] = member.imageUrl;
        }
      });

      setFormData(newFormData);
      setPreviewUrls(newPreviewUrls);
    }
  }, [members]);

  const fetchMembers = async () => {
    try {
      const response = await axios.get(`${import.meta.env.VITE_API_BASE_URL}/api/executive`);
      const membersData = Array.isArray(response.data) ? response.data : [];
      setMembers(membersData);
    } catch (error) {
      console.error('Error fetching members:', error);
      setMessage('Error fetching members');
      setMembers([]);
    }
  };

  const handleChange = (designation, index, e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [designation]: prevState[designation].map((item, i) =>
        i === index ? { ...item, [name]: value } : item
      ),
    }));
  };

  const handleImageUpload = (designation, index) => {
    const widget = window.cloudinary.createUploadWidget(
      {
        cloudName: import.meta.env.VITE_CLOUDINARY_CLOUD_NAME,
        uploadPreset: import.meta.env.VITE_CLOUDINARY_UPLOAD_PRESET,
        sources: ['local', 'url', 'camera'],
        clientAllowedFormats: ['jpg', 'jpeg', 'png'],
        maxFiles: 1,
        resourceType: 'image',
        maxImageFileSize: 5000000,
        folder: 'executive-council',
      },
      (error, result) => {
        if (!error && result && result.event === 'success') {
          setFormData(prevState => ({
            ...prevState,
            [designation]: prevState[designation].map((item, i) =>
              i === index ? { ...item, imageUrl: result.info.secure_url } : item
            ),
          }));
          setPreviewUrls(prevState => ({
            ...prevState,
            [designation]: prevState[designation].map((url, i) =>
              i === index ? result.info.secure_url : url
            ),
          }));
        } else if (error) {
          console.error('Upload error:', error);
          setMessage('Error uploading image');
        }
      }
    );

    widget.open();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setMessage('');

    try {
      const membersToSubmit = [];
      Object.entries(formData).forEach(([designation, members]) => {
        members.forEach((member, index) => {
          if (member.name && member.imageUrl) {
            membersToSubmit.push({
              _id: member._id,
              name: member.name,
              imageUrl: member.imageUrl,
              designation: `${designation.replace(/([A-Z])/g, ' $1').trim()}${index + 1}`
            });
          }
        });
      });

      for (const memberData of membersToSubmit) {
        if (memberData._id) {
          await axios.patch(
            `${import.meta.env.VITE_API_BASE_URL}/api/executive/${memberData._id}`,
            memberData,
            { headers: { 'Content-Type': 'application/json' } }
          );
        } else {
          await axios.post(
            `${import.meta.env.VITE_API_BASE_URL}/api/executive`,
            memberData,
            { headers: { 'Content-Type': 'application/json' } }
          );
        }
      }

      setMessage('Upload successful');
      setEditMode(false);
      setEditingMember(null);
      await fetchMembers();
    } catch (error) {
      console.error("Upload error:", error);
      setMessage(error.response?.data?.message || 'Error uploading data');
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${import.meta.env.VITE_API_BASE_URL}/api/executive/${id}`);
      setMessage('Deleted successfully');
      await fetchMembers();
    } catch (error) {
      console.error('Delete error:', error);
      setMessage('Error deleting data');
    }
  };

  return (
    <div className="p-6">
      <h2 className="text-2xl font-bold mb-4">Executive Council Details</h2>
      {message && (
        <div className={`p-4 mb-4 rounded ${message.includes('Error') ? 'bg-red-100 text-red-700' : 'bg-green-100 text-green-700'}`}>
          {message}
        </div>
      )}
      
      <form onSubmit={handleSubmit} className="space-y-4">
        {Object.entries(formData).map(([designation, members]) => (
          <div key={designation} className="space-y-4 p-4 border rounded-lg bg-white">
            <h3 className="text-xl font-semibold">
              {designation.replace(/([A-Z])/g, ' $1').trim()} ({members.length} position{members.length > 1 ? 's' : ''})
            </h3>
            {members.map((member, index) => (
              <div key={index} className="space-y-2 p-4 border rounded">
                <h4 className="font-medium">Position {index + 1}</h4>
                <div className="flex gap-4">
                  <div className="flex-1">
                    <input
                      type="text"
                      name="name"
                      placeholder="Enter name"
                      value={member.name}
                      onChange={(e) => handleChange(designation, index, e)}
                      className="w-full p-2 border border-gray-300 rounded"
                    />
                  </div>
                  <button
                    type="button"
                    onClick={() => handleImageUpload(designation, index)}
                    className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded transition-colors"
                  >
                    Upload Image
                  </button>
                </div>
                <div className="mt-2">
                  <img
                    src={previewUrls[designation][index]}
                    alt={`${designation} ${index + 1} Preview`}
                    className="w-32 h-32 object-cover rounded-full border-2 border-gray-200"
                  />
                </div>
              </div>
            ))}
          </div>
        ))}
        <button
          type="submit"
          className={`w-full py-3 rounded transition-colors ${loading ? 'bg-gray-400 cursor-not-allowed' : 'bg-green-500 hover:bg-green-600 text-white'}`}
          disabled={loading}
        >
          {loading ? 'Uploading...' : (editMode ? 'Update' : 'Submit All')}
        </button>
      </form>

      <div className="mt-8">
        <h3 className="text-xl font-semibold mb-4">Existing Members</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {Array.isArray(members) && members.map(member => (
            <div key={member._id} className="p-4 border rounded-lg bg-white">
              <div className="flex items-center justify-between mb-2">
                <h4 className="font-semibold">{member.name}</h4>
                <div className="space-x-2">
                  <button
                    onClick={() => handleDelete(member._id)}
                    className="bg-red-500 hover:bg-red-600 text-white px-3 py-1 rounded text-sm transition-colors"
                  >
                    Delete
                  </button>
                </div>
              </div>
              <p className="text-gray-600 mb-2">{member.designation}</p>
              <img
                src={member.imageUrl}
                alt={member.name}
                className="w-full h-48 object-cover rounded-lg"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AdminExecutiveCouncil;