import Cookies from 'js-cookie';

/**
 * Gets the JWT token from cookies.
 * @returns {string|null} The JWT token.
 */
const getToken = () => Cookies.get('jwtToken');

/**
 * Sets authentication-related cookies.
 * @param {string} jwtToken - JWT token.
 * @param {string} membershipId - User's membership ID.
 */
const setAuthData = (jwtToken, membershipId) => {
  Cookies.set('jwtToken', jwtToken, { expires: 7, sameSite: 'Lax' });
  Cookies.set('membershipId', membershipId, { expires: 7, sameSite: 'Lax' });
};

/**
 * Clears all authentication-related cookies.
 */
const clearAuthData = () => {
  Cookies.remove('jwtToken');
  Cookies.remove('membershipId');
};

/**
 * Checks if the user is authenticated.
 * @returns {boolean} True if authenticated, otherwise false.
 */
const isAuthenticated = () => !!getToken();

/**
 * Stores the JWT token for admin login in cookies.
 * @param {string} adminJwtToken - Admin's JWT token.
 */
const setAdminAuthToken = (adminJwtToken) => {
  Cookies.set('adminJwtToken', adminJwtToken, { expires: 7, sameSite: 'Lax' });
};

/**
 * Gets the JWT token for admin login from cookies.
 * @returns {string|null} The admin JWT token.
 */
const getAdminAuthToken = () => Cookies.get('adminJwtToken');

/**
 * Clears the admin JWT token from cookies.
 */
const clearAdminAuthToken = () => {
  Cookies.remove('adminJwtToken');
};

export const isUserLoggedIn = () => {
  const token = Cookies.get('AuthToken');
  return !!token;
};

export {
  getToken,
  setAuthData,
  clearAuthData,
  isAuthenticated,
  setAdminAuthToken,
  getAdminAuthToken,
  clearAdminAuthToken,
};
