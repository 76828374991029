import React from 'react'
import { GoArrowRight } from 'react-icons/go'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <div className="w-full h-auto bg-[#008CFF] pt-10">
    {/* Top Section */}
    <div className="top w-full h-auto flex flex-col md:flex-row justify-center items-center">
      {/* Left Section */}
      <div className="left w-full md:w-[50%] h-full flex justify-center items-center mb-5 md:mb-0">
        <img
          className="w-[40vw] md:w-[13vw] pb-5 md:pb-0"
          src="/TASOI LOGO.webp"
          alt="TASOI Logo"
        />
      </div>
  
      {/* Right Section */}
      <div className="right w-full md:w-[60%] flex flex-col justify-center items-center gap-5 text-white underline">
        {/* First Row */}
        <div className="rightTop grid grid-cols-2 gap-4 text-[4vw] md:grid-cols-4 md:gap-5 md:text-[1vw] w-full px-5 md:w-[60%] justify-items-center">
  <a href="#section1" className="text-center hover:text-red-500 hover:underline">Home</a>
  <a href="#section2" className="text-center hover:text-red-500 hover:underline">About Us</a>
  <a href="#section3" className="text-center hover:text-red-500 hover:underline">Activities</a>
  <a href="#section5" className="text-center hover:text-red-500 hover:underline">Membership</a>
</div>

{/* Second Row */}
<div className="rightTop grid grid-cols-2 gap-4 text-[4vw] md:grid-cols-4 md:gap-5 md:text-[1vw] w-full px-5 md:w-[70%] justify-items-center">
  <a href="#section4" className="text-center hover:text-red-500 hover:underline">Photo Gallery</a>
  <a href="#section6" className="text-center hover:text-red-500 hover:underline">Journal</a>
  <a href="#section7" className="text-center hover:text-red-500 hover:underline">Contact Us</a>
  <Link to="/admin/login" className="text-center hover:text-red-500 hover:underline">Admin</Link>
</div>
  
        {/* External Links Button */}
        <div className="flex w-full md:w-[60%] justify-center items-center mt-4">
          {/* <Link
            to="/external-links"
            className="flex justify-center items-center gap-2 text-[4vw] md:text-[1vw] bg-white text-[#008CFF] w-[90%] h-[7vh] md:h-[5vh] rounded-full font-bold"
          >
            EXTERNAL Links <GoArrowRight />
          </Link> */}
          <div className='md:w-[24vw]'>
        
        <Link   to="/external-links">
<button
  className="relative inline-flex h-12 w-[80vw] md:w-[26vw] active:scale-95 transistion items-center justify-center    overflow-hidden rounded-lg p-[1px] focus:outline-none"
>
  <span
    className="absolute  inset-[-1000%] animate-[spin_2s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#e7029a_0%,#d8ff00_50%,#FF0000_100%)]"
  >
  </span>
  <span
    className="inline-flex h-[95%] w-[99%] cursor-pointer items-center justify-center rounded-lg bg-white px-7 font-bold text-xl  text-black backdrop-blur-3xl gap-2 "
  >
      External Links <GoArrowRight />
  </span>
</button>
</Link>
          </div>
        </div>
      </div>
    </div>
  
    {/* Bottom Section */}
    <div className="bottom w-full h-auto mt-5 flex justify-center items-center">
      <div className="text w-[90%] border-t-2 flex justify-center items-center pt-4 md:pt-6">
        <h1 className="font-bold text-[5vw] md:text-[2vw] text-center leading-tight">
          THE <span className="text-white">AERONAUTICAL</span> SOCIETY OF INDIA
        </h1>
      </div>
    </div>
  </div>
  
  )
}

export default Footer
