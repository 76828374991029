import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ChevronDown } from 'lucide-react';

const ActivitiesPage = () => {
  const [activitiesAhead, setActivitiesAhead] = useState([]);
  const [activitiesCompleted, setActivitiesCompleted] = useState([]);

  useEffect(() => {
    fetchActivities();
  }, []);

  const fetchActivities = async () => {
    try {
      const response = await axios.get(`${import.meta.env.VITE_API_BASE_URL}/api/activities`);
      const activities = Array.isArray(response.data) ? response.data : response.data.activities || [];
      const ahead = activities.filter(activity => !activity.completed);
      const completed = activities.filter(activity => activity.completed);

      setActivitiesAhead(ahead);
      setActivitiesCompleted(completed);
    } catch (error) {
      console.error("Error fetching activities:", error);
    }
  };

  const ActivityCard = ({ title, endDate, isCompleted }) => {
    const [isOpen, setIsOpen] = useState(false);

    const formatDate = (dateString) => {
      return new Date(dateString).toLocaleDateString();
    };

    return (
      <div className="mb-6">
        <div
          className="bg-white rounded-2xl shadow-lg shadow-gray-200 p-5 transition-shadow duration-300
                     hover:shadow-xl hover:shadow-gray-300 relative
                     before:absolute before:inset-0 before:rounded-2xl before:shadow-[inset_0px_4px_8px_rgba(0,0,0,0.1)]"
        >
          <div className={`flex items-center justify-between ${isCompleted ? 'cursor-pointer' : ''}`}
            onClick={() => isCompleted && setIsOpen(!isOpen)}
          >
            <h3 className="text-blue-500 text-lg font-normal w-[90%]">{title}</h3>
            {isCompleted && (
              <ChevronDown
                className={`min-h-[24px] min-w-[24px] text-blue-500 transform transition-transform duration-200 ${isOpen ? 'rotate-180' : ''}`}
              />
            )}
          </div>
          {isCompleted && (
            <div
              className={`overflow-hidden transition-[max-height] duration-200 ease-in-out ${isOpen ? 'max-h-28' : 'max-h-0'}`}
            >
              <div className="pb-4 text-base text-gray-500 mt-4">
                <div className="flex gap-2">
                  <span className="text-gray-400">Completed On :</span>
                  <span>{formatDate(endDate)}</span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div id="section3" className="w-full min-h-screen bg-gray-50 pb-10">
      <div>
        <h2 className="text-2xl font-bold bg-blue-500 h-[8vh] w-full backdrop-filter bg-opacity-40 backdrop-blur-sm text-black-900 items-center flex justify-center">
          ACTIVITIES
        </h2>
      </div>

      <div className="mt-10 flex flex-col md:flex-row gap-12 justify-around items-center">
        <div className="md:w-1/2">
          <div className="rounded-tr-[48px] rounded-br-[48px]"> 
            <img 
              src="/aircraft (3).webp"
              alt="Fighter Jet"
              className="w-full h-full"
            />
          </div>
        </div>

        <div className="md:w-1/2 space-y-8">
          <div>
            <h2 className="text-xl mb-6 flex items-center justify-center">Activities Ahead</h2>
            {activitiesAhead.length > 0 ? (
              activitiesAhead.map((activity, index) => (
                <ActivityCard
                  key={index}
                  title={activity.name}
                  isCompleted={false}
                />
              ))
            ) : (
              <div className="text-gray-500 text-center">No activities are present at this moment</div>
            )}
          </div>

          <div>
            <h2 className="text-xl mb-6 flex items-center justify-center">Activities Completed</h2>
            {activitiesCompleted.map((activity, index) => (
              <ActivityCard
                key={index}
                title={activity.name}
                endDate={activity.endDate}
                isCompleted={true}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActivitiesPage;
