import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';

const JournalDetail = () => {
  const { id } = useParams();
  const [journal, setJournal] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchJournal = async () => {
      try {
        const response = await axios.get(`${import.meta.env.VITE_API_BASE_URL}/api/journal/${id}`);
        setJournal(response.data);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch journal details');
        setLoading(false);
      }
    };

    fetchJournal();
  }, [id]);

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error || !journal) {
    return (
      <div className="min-h-screen flex flex-col items-center justify-center">
        <div className="text-xl mb-4">{error || 'Journal not found'}</div>
        <Link to="/" className="text-blue-500 hover:underline">Return to Home</Link>
      </div>
    );
  }

  return (
    <div className="w-full min-h-screen bg-gray-50">
      {/* Header */}
      <div className="header">
        <h2 className="text-2xl font-bold bg-blue-500 h-[8vh] w-full backdrop-filter bg-opacity-40 backdrop-blur-sm text-black-900 items-center flex justify-center">
          JOURNAL DETAILS
        </h2>
      </div>

      {/* Main Content */}
      <div className="container mx-auto px-4 py-8">
        <div className="bg-[#D6E6FF] rounded-[23px] overflow-hidden shadow-lg">
          {/* Journal Info Banner */}
          {journal.section !== 'latest' && (
            <div className="bg-blue-500 bg-opacity-40 text-black py-3 px-6">
              <span className="font-semibold">
                {journal.month} {journal.year} - Journal No. {journal.journalNo}
              </span>
            </div>
          )}

          <div className="p-6 md:p-8">
            {/* Title */}
            <h1 className="text-3xl md:text-4xl font-bold mb-8 text-center">
              {journal.title}
            </h1>

            {/* Content Grid */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-start">
              {/* Left Column - Image */}
              <div className="w-full">
                <div className="rounded-[23px] overflow-hidden shadow-xl">
                  <img 
                    src={journal.image} 
                    alt={journal.title}
                    className="w-full h-[40vh] md:h-[50vh] object-cover transform hover:scale-105 transition-transform duration-300"
                  />
                </div>
              </div>

              {/* Right Column - Content */}
              <div className="w-full">
                <div className="bg-white bg-opacity-50 rounded-[15px] p-6 shadow-md">
                  <div 
                    dangerouslySetInnerHTML={{ __html: journal.content }}
                    className="prose max-w-none text-gray-800 leading-relaxed"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Back Button */}
        <div className="mt-8 text-center">
          <Link 
            to="/" 
            className="inline-block bg-blue-500 text-white px-6 py-3 rounded-full hover:bg-blue-600 transition-colors duration-300"
          >
            Back to Home
          </Link>
        </div>
      </div>
    </div>
  );
};

export default JournalDetail; 