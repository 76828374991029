import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { getAdminAuthToken } from '../../utilis/auth';

const AdminHome = () => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({ type: '', text: '' });
  const [images, setImages] = useState([]);
  const [membershipLink, setMembershipLink] = useState('');
  const [marquee, setmarquee] = useState('');
  const [newPassword, setNewPassword] = useState(''); 
  const [MembId, setMembId] = useState(''); 
  const [passwordLoading, setPasswordLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const adminToken = getAdminAuthToken();
    if (!adminToken) {
      navigate('/admin/login');
    }
  }, [navigate]);

  // Load Cloudinary Widget
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://upload-widget.cloudinary.com/global/all.js';
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handlePasswordChange = (e) => {
    setNewPassword(e.target.value);
  };

  const handleMembershipId = (e) => {
    setMembId(e.target.value);
  };

  // Updated password change handler
  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    
    // Validation checks
    if (!MembId) {
      setMessage({ type: 'error', text: 'Please enter Membership ID.' });
      return;
    }

    if (!newPassword) {
      setMessage({ type: 'error', text: 'Please enter a new password.' });
      return;
    }

    // Password strength validation (optional but recommended)
    if (newPassword.length < 8) {
      setMessage({ type: 'error', text: 'Password must be at least 8 characters long.' });
      return;
    }

    try {
      setPasswordLoading(true);

      // Get admin token for authorization
      const token = getAdminAuthToken();

      const response = await axios.patch(
        `${import.meta.env.VITE_API_BASE_URL}/api/users/update-password`,
        { 
          membershipId: MembId, 
          newPassword 
        }, 
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Handle successful response
      if (response.status === 200) {
        setMessage({ 
          type: 'success', 
          text: 'Password successfully updated!' 
        });
        alert("Password successfully updated!")
        
        // Clear input fields
        setMembId('');
        setNewPassword('');
      } else {
        setMessage({ 
          type: 'error', 
          text: 'Failed to update password.' 
        });
        alert("Password Updation  failed!")
      }

      // Clear message after 3 seconds
      setTimeout(() => setMessage({ type: '', text: '' }), 3000);
    } catch (error) {
      // More detailed error handling
      const errorMsg = error.response?.data?.message || 
        'Error updating password. Please try again.';
      
      setMessage({ 
        type: 'error', 
        text: errorMsg 
      });
    } finally {
      setPasswordLoading(false);
    }
  };

  // Initialize upload widget
  const initializeWidget = (oldImageUrl = null) => {
    if (!window.cloudinary) {
      showMessage('error', 'Upload widget is not ready. Please refresh the page.');
      return null;
    }

    return window.cloudinary.createUploadWidget(
      {
        cloudName: import.meta.env.VITE_CLOUDINARY_CLOUD_NAME,
        uploadPreset: import.meta.env.VITE_CLOUDINARY_UPLOAD_PRESET,
        folder: 'landing-page',
        maxFiles: 999,
        sources: ['local', 'url', 'camera'],
        resourceType: 'image',
        maxImageFileSize: 5000000,
        clientAllowedFormats: ['jpg', 'jpeg', 'png'],
      },
      async (error, result) => {
        if (error) {
          showMessage('error', `Upload failed: ${error.message}`);
          setLoading(false);
          return;
        }

        if (result.event === 'success') {
          await handleImageUploadSuccess(result.info.secure_url, oldImageUrl);
        }
      }
    );
  };

  // Show message helper
  const showMessage = (type, text) => {
    setMessage({ type, text });
    setTimeout(() => setMessage({ type: '', text: '' }), 5000);
  };

  // Handle successful image upload
  const handleImageUploadSuccess = async (newImageUrl, oldImageUrl = null) => {
    try {
      setLoading(true);
      const endpoint = `${import.meta.env.VITE_API_BASE_URL}/api/landing-page`;
      let response;

      if (oldImageUrl) {
        // Replace existing image
        response = await axios.put(endpoint, {
          updates: { oldUrl: oldImageUrl, newUrl: newImageUrl }
        });
      } else {
        // Add new image
        response = await axios.put(endpoint, {
          addImages: [newImageUrl]
        });
      }

      if (response.data.landingPage) {
        setImages(response.data.landingPage.images);
        showMessage('success', oldImageUrl ? 'Image replaced successfully!' : 'Image uploaded successfully!');
      }
    } catch (err) {
      showMessage('error', `Error saving image: ${err.response?.data?.message || err.message}`);
    } finally {
      setLoading(false);
    }
  };

  // Handle image upload
  const handleUpload = (oldImageUrl = null) => {
    setLoading(true);
    const widget = initializeWidget(oldImageUrl);
    if (widget) {
      widget.open();
    } else {
      setLoading(false);
    }
  };

  // Fetch images
  const fetchImages = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${import.meta.env.VITE_API_BASE_URL}/api/landing-page`);
      if (response.data.images) {
        setImages(response.data.images);
      }
    } catch (error) {
      showMessage('error', `Error fetching images: ${error.response?.data?.message || error.message}`);
    } finally {
      setLoading(false);
    }
  };

  // Fetch current membership link
  const fetchCurrentLink = async () => {
    try {
      const response = await axios.get(`${import.meta.env.VITE_API_BASE_URL}/api/landing-page/membership-link`);
      if (response.data.membershipLink) {
        setMembershipLink(response.data.membershipLink);
      }
    } catch (error) {
      showMessage('error', `Error fetching current link: ${error.response?.data?.message || error.message}`);
    }
  };

  // Initial data fetch
  useEffect(() => {
    fetchImages();
    fetchCurrentLink();
  }, []);

  // Fetch and set marquee text
  useEffect(() => {
    const fetchMarqueeText = async () => {
      try {
        const response = await axios.get(
          `${import.meta.env.VITE_API_BASE_URL}/api/landing-page/`
        );
        setmarquee(response.data.marqueeText);
      } catch (error) {
        console.log('Error fetching marquee text:', error);
      } 
    };
    fetchMarqueeText();
  },[]);

  // Handle membership link update
  const handleLinkUpdate = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.put(
        `${import.meta.env.VITE_API_BASE_URL}/api/landing-page/membership-link`,
        { link: membershipLink }
      );
      
      if (response.data.message) {
        showMessage('success', response.data.message);
      }
    } catch (error) {
      showMessage('error', `Error updating link: ${error.response?.data?.message || error.message}`);
    } finally {
      setLoading(false);
    }
  };

  // Handle marquee text update
  const HandleMarqueeText = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.put(
        `${import.meta.env.VITE_API_BASE_URL}/api/landing-page/`,
        { marqueeText: marquee }
      );
      
      if (response.data.message) {
        showMessage('success', response.data.message);
      }
    } catch (error) {
      showMessage('error', `Error updating marquee text: ${error.response?.data?.message || error.message}`);
    } finally {
      setLoading(false);
    }
  };

  // Handle single image deletion
  const handleDeleteImage = async (imageUrl) => {
    if (!window.confirm('Are you sure you want to delete this image?')) return;
    
    try {
      setLoading(true);
      const updatedImages = images.filter(img => img !== imageUrl);
      const response = await axios.put(
        `${import.meta.env.VITE_API_BASE_URL}/api/landing-page`,
        { images: updatedImages }
      );
      
      if (response.status === 200) {
        setImages(updatedImages);
        showMessage('success', 'Image deleted successfully!');
      }
    } catch (error) {
      showMessage('error', `Error deleting image: ${error.response?.data?.message || error.message}`);
    } finally {
      setLoading(false);
    }
  };

  // Handle deletion of all images
  const handleDeleteAllImages = async () => {
    if (!window.confirm('Are you sure you want to delete all images? This action cannot be undone.')) return;
    
    try {
      setLoading(true);
      const response = await axios.put(
        `${import.meta.env.VITE_API_BASE_URL}/api/landing-page`,
        { images: [] }
      );
      
      if (response.status === 200) {
        setImages([]);
        showMessage('success', 'All images deleted successfully!');
      }
    } catch (error) {
      showMessage('error', `Error deleting images: ${error.response?.data?.message || error.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="p-6 max-w-7xl mx-auto">
      <h2 className="text-2xl font-bold mb-6">Admin Dashboard</h2>
      
      {/* Message Display */}
      {message.text && (
        <div 
          className={`mb-4 p-4 rounded-lg ${
            message.type === 'error' ? 'bg-red-100 text-red-700 border border-red-200' : 
            'bg-green-100 text-green-700 border border-green-200'
          }`}
        >
          {message.text}
        </div>
      )}
      
      {/* Landing Page Image Management */}
      <div className="bg-white rounded-lg shadow-md border p-6 mb-6">
        <h3 className="text-xl font-semibold mb-4">Landing Page Images</h3>
        
        <div className="flex flex-wrap gap-4 mb-6">
          <button 
            onClick={() => handleUpload()}
            className="bg-blue-500 text-white px-6 py-2 rounded-md hover:bg-blue-600 transition-colors disabled:bg-blue-300 disabled:cursor-not-allowed flex items-center gap-2"
            disabled={loading}
          >
            {loading ? (
              <>
                <div className="animate-spin h-4 w-4 border-2 border-white border-t-transparent rounded-full" />
                Processing...
              </>
            ) : (
              'Upload New Image'
            )}
          </button>

          <button 
            onClick={handleDeleteAllImages}
            className="bg-red-500 text-white px-6 py-2 rounded-md hover:bg-red-600 transition-colors disabled:bg-red-300 disabled:cursor-not-allowed"
            disabled={loading || images.length === 0}
          >
            Delete All Images
          </button>
        </div>
        
        {images.length === 0 ? (
          <div className="text-center py-8 text-gray-500">
            No images uploaded yet. Click "Upload New Image" to add some.
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {images.map((url, index) => (
              url !== 'NA' && (
                <div key={index} className="relative group rounded-lg overflow-hidden shadow-md border">
                  <img
                    src={url}
                    alt={`Landing page image ${index + 1}`}
                    className="w-full h-48 object-cover"
                  />
                  <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-all duration-200 flex items-center justify-center gap-4">
                    <button 
                      onClick={() => handleUpload(url)}
                      className="bg-yellow-500 px-4 py-2 rounded-md hover:bg-yellow-600 transition-colors text-white"
                      disabled={loading}
                    >
                      Replace
                    </button>
                    <button 
                      onClick={() => handleDeleteImage(url)}
                      className="bg-red-500 px-4 py-2 rounded-md hover:bg-red-600 transition-colors text-white"
                      disabled={loading}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              )
            ))}
          </div>
        )}
      </div>

      {/* Marquee Text Management */}
      <div className="bg-white rounded-lg shadow-md border p-6 mb-5">
        <h3 className="text-xl font-semibold mb-4">Marquee Text</h3>
        <form onSubmit={HandleMarqueeText} className="space-y-4">
          <input 
            type="text" 
            value={marquee}
            onChange={(e) => setmarquee(e.target.value)} 
            className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none" 
            placeholder="Enter Marquee Text"
            required
          />
          <button 
            type="submit" 
            className="bg-green-500 text-white px-6 py-2 rounded-md hover:bg-green-600 transition-colors disabled:bg-green-300 disabled:cursor-not-allowed flex items-center gap-2"
            disabled={loading}
          >
          {loading ? (
              <>
                <div className="animate-spin h-4 w-4 border-2 border-white border-t-transparent rounded-full" />
                Updating...
              </>
            ) : (
              'Update Text'
            )}
          </button>
        </form>
      </div>
      
      {/* Membership Link Management */}
      <div className="bg-white rounded-lg shadow-md border p-6 mb-6">
        <h3 className="text-xl font-semibold mb-4">Membership Link</h3>
        <form onSubmit={handleLinkUpdate} className="space-y-4">
          <input 
            type="url" 
            value={membershipLink} 
            onChange={(e) => setMembershipLink(e.target.value)} 
            className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none" 
            placeholder="Enter membership link"
            required
          />
          <button 
            type="submit" 
            className="bg-green-500 text-white px-6 py-2 rounded-md hover:bg-green-600 transition-colors disabled:bg-green-300 disabled:cursor-not-allowed flex items-center gap-2"
            disabled={loading}
          >
            {loading ? (
              <>
                <div className="animate-spin h-4 w-4 border-2 border-white border-t-transparent rounded-full" />
                Updating...
              </>
            ) : (
              'Update Link'
            )}
          </button>
        </form>
      </div>

      {/* Change User Password Section */}
      <div className="bg-white rounded-lg shadow-md border p-6">
        <form onSubmit={handlePasswordSubmit} className="space-y-4">
          <h3 className="text-lg font-bold text-gray-800">Change Password</h3>
          
          {/* Membership ID Input */}
          <div>
            <label
              htmlFor="MembId"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              Membership ID
            </label>
            <input
              type="text"
              id="MembId"
              value={MembId}
              onChange={handleMembershipId}
              className="w-full px-3 py-2 border border-gray-300 rounded-md"
              placeholder="Enter Membership ID"
              required
            />
          </div>

          {/* New Password Input */}
          <div>
            <label
              htmlFor="newPassword"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              New Password
            </label>
            <input
              type="password"
              id="newPassword"
              value={newPassword}
              onChange={handlePasswordChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md"
              placeholder="Enter new password"
              minLength={8}
              required
            />
          </div>

          {/* Submit Button */}
          <div>
            <button
              type="submit"
              disabled={passwordLoading}
              className={`w-full py-2 px-4 rounded-md ${
                passwordLoading
                  ? 'bg-blue-300 cursor-not-allowed'
                  : 'bg-green-500 hover:bg-green-600 text-white'
              }`}
            >
              {passwordLoading ? 'Updating...' : 'Update Password'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AdminHome;