import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaTrash, FaEdit, FaSave, FaTimes } from 'react-icons/fa';

const AdminExternalLinks = () => {
  const [groups, setGroups] = useState([]);
  const [newGroup, setNewGroup] = useState('');
  const [newLink, setNewLink] = useState({ group: '', label: '', url: '' });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editMode, setEditMode] = useState({
    type: null, // 'group' or 'link'
    id: null,
    data: null
  });

  // Fetch all groups and their links
  const fetchGroups = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${import.meta.env.VITE_API_BASE_URL}/api/external-links`);
      setGroups(Array.isArray(response.data) ? response.data : []);
    } catch (error) {
      console.error('Error fetching groups:', error);
      setError('Failed to fetch groups');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchGroups();
  }, []);

  // Start editing group or link
  const startEdit = (type, id, data) => {
    setEditMode({ type, id, data: { ...data } });
  };

  // Cancel editing
  const cancelEdit = () => {
    setEditMode({ type: null, id: null, data: null });
  };

  // Update group name
  const handleUpdateGroup = async (groupId) => {
    try {
      await axios.put(`${import.meta.env.VITE_API_BASE_URL}/api/external-links/groups/${groupId}`, {
        name: editMode.data.name
      });
      cancelEdit();
      fetchGroups();
    } catch (error) {
      console.error('Error updating group:', error);
      setError('Failed to update group');
    }
  };

  // Update link
  const handleUpdateLink = async (linkId) => {
    try {
      await axios.put(`${import.meta.env.VITE_API_BASE_URL}/api/external-links/links/${linkId}`, editMode.data);
      cancelEdit();
      fetchGroups();
    } catch (error) {
      console.error('Error updating link:', error);
      setError('Failed to update link');
    }
  };

  // Add new group
  const handleAddGroup = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${import.meta.env.VITE_API_BASE_URL}/api/external-links/groups`, { name: newGroup });
      setNewGroup('');
      fetchGroups();
    } catch (error) {
      console.error('Error adding group:', error);
      setError('Failed to add group');
    }
  };

  // Add new link
  const handleAddLink = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${import.meta.env.VITE_API_BASE_URL}/api/external-links/links`, newLink);
      setNewLink({ group: '', label: '', url: '' });
      fetchGroups();
    } catch (error) {
      console.error('Error adding link:', error);
      setError('Failed to add link');
    }
  };

  // Delete group
  const handleDeleteGroup = async (groupId) => {
    if (window.confirm('Are you sure you want to delete this group?')) {
      try {
        await axios.delete(`${import.meta.env.VITE_API_BASE_URL}/api/external-links/groups/${groupId}`);
        fetchGroups();
      } catch (error) {
        console.error('Error deleting group:', error);
        setError('Failed to delete group');
      }
    }
  };

  // Delete link
  const handleDeleteLink = async (linkId) => {
    if (window.confirm('Are you sure you want to delete this link?')) {
      try {
        await axios.delete(`${import.meta.env.VITE_API_BASE_URL}/api/external-links/links/${linkId}`);
        fetchGroups();
      } catch (error) {
        console.error('Error deleting link:', error);
        setError('Failed to delete link');
      }
    }
  };

  if (loading) return <div className="p-8">Loading...</div>;
  if (error) return <div className="p-8 text-red-500">{error}</div>;

  return (
    <div className="p-8">
      <h1 className="text-2xl font-bold mb-8">Manage External Links</h1>

      {/* Add New Group Form */}
      <div className="bg-white p-6 rounded-lg shadow-md mb-8">
        <h2 className="text-xl font-semibold mb-4">Add New Group</h2>
        <form onSubmit={handleAddGroup} className="flex gap-4">
          <input
            type="text"
            value={newGroup}
            onChange={(e) => setNewGroup(e.target.value)}
            placeholder="Enter group name"
            className="flex-1 p-2 border rounded"
            required
          />
          <button
            type="submit"
            className="bg-[#008CFF] text-white px-4 py-2 rounded hover:bg-blue-600"
          >
            Add Group
          </button>
        </form>
      </div>

      {/* Add New Link Form */}
      <div className="bg-white p-6 rounded-lg shadow-md mb-8">
        <h2 className="text-xl font-semibold mb-4">Add New Link</h2>
        <form onSubmit={handleAddLink} className="space-y-4">
          <select
            value={newLink.group}
            onChange={(e) => setNewLink({ ...newLink, group: e.target.value })}
            className="w-full p-2 border rounded"
            required
          >
            <option value="">Select Group</option>
            {groups.map((group) => (
              <option key={group._id} value={group._id}>
                {group.name}
              </option>
            ))}
          </select>
          <input
            type="text"
            value={newLink.label}
            onChange={(e) => setNewLink({ ...newLink, label: e.target.value })}
            placeholder="Enter link label"
            className="w-full p-2 border rounded"
            required
          />
          <input
            type="url"
            value={newLink.url}
            onChange={(e) => setNewLink({ ...newLink, url: e.target.value })}
            placeholder="Enter URL"
            className="w-full p-2 border rounded"
            required
          />
          <button
            type="submit"
            className="bg-[#008CFF] text-white px-4 py-2 rounded hover:bg-blue-600"
          >
            Add Link
          </button>
        </form>
      </div>

      {/* Display Groups and Links */}
      <div className="space-y-6">
        {groups.length === 0 ? (
          <p className="text-gray-500">No groups added yet.</p>
        ) : (
          groups.map((group) => (
            <div key={group._id} className="bg-white p-6 rounded-lg shadow-md">
              <div className="flex justify-between items-center mb-4">
                {editMode.type === 'group' && editMode.id === group._id ? (
                  <div className="flex items-center gap-2">
                    <input
                      type="text"
                      value={editMode.data.name}
                      onChange={(e) => setEditMode({
                        ...editMode,
                        data: { ...editMode.data, name: e.target.value }
                      })}
                      className="p-2 border rounded"
                    />
                    <button
                      onClick={() => handleUpdateGroup(group._id)}
                      className="text-green-500 hover:text-green-700"
                    >
                      <FaSave />
                    </button>
                    <button
                      onClick={cancelEdit}
                      className="text-gray-500 hover:text-gray-700"
                    >
                      <FaTimes />
                    </button>
                  </div>
                ) : (
                  <h2 className="text-xl font-semibold text-[#008CFF]">
                    {group.name}
                  </h2>
                )}
                <div className="flex gap-2">
                  {editMode.type !== 'group' && (
                    <button
                      onClick={() => startEdit('group', group._id, { name: group.name })}
                      className="text-blue-500 hover:text-blue-700"
                    >
                      <FaEdit />
                    </button>
                  )}
                  <button
                    onClick={() => handleDeleteGroup(group._id)}
                    className="text-red-500 hover:text-red-700"
                  >
                    <FaTrash />
                  </button>
                </div>
              </div>
              <div className="space-y-3">
                {group.links && group.links.length > 0 ? (
                  group.links.map((link) => (
                    <div
                      key={link._id}
                      className="flex justify-between items-center p-2 hover:bg-gray-50"
                    >
                      {editMode.type === 'link' && editMode.id === link._id ? (
                        <div className="flex-1 flex items-center gap-2">
                          <input
                            type="text"
                            value={editMode.data.label}
                            onChange={(e) => setEditMode({
                              ...editMode,
                              data: { ...editMode.data, label: e.target.value }
                            })}
                            className="p-2 border rounded w-1/3"
                            placeholder="Label"
                          />
                          <input
                            type="url"
                            value={editMode.data.url}
                            onChange={(e) => setEditMode({
                              ...editMode,
                              data: { ...editMode.data, url: e.target.value }
                            })}
                            className="p-2 border rounded w-1/2"
                            placeholder="URL"
                          />
                          <button
                            onClick={() => handleUpdateLink(link._id)}
                            className="text-green-500 hover:text-green-700"
                          >
                            <FaSave />
                          </button>
                          <button
                            onClick={cancelEdit}
                            className="text-gray-500 hover:text-gray-700"
                          >
                            <FaTimes />
                          </button>
                        </div>
                      ) : (
                        <>
                          <a
                            href={link.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-gray-700 hover:text-[#008CFF]"
                          >
                            {link.label}
                          </a>
                          <div className="flex gap-2">
                            <button
                              onClick={() => startEdit('link', link._id, {
                                label: link.label,
                                url: link.url
                              })}
                              className="text-blue-500 hover:text-blue-700"
                            >
                              <FaEdit />
                            </button>
                            <button
                              onClick={() => handleDeleteLink(link._id)}
                              className="text-red-500 hover:text-red-700"
                            >
                              <FaTrash />
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                  ))
                ) : (
                  <p className="text-gray-500">No links in this group.</p>
                )}
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default AdminExternalLinks;