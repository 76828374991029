import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';

const MemberModification = () => {
  const [formData, setFormData] = useState({
    memId: '',
    name: '',
    contactNo: '',
    unit: '',
    address: '',
    profession: '',
  });

  const [newPassword, setNewPassword] = useState(''); // Separate state for password
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [passwordLoading, setPasswordLoading] = useState(false); // Loading for password update

  const userId = Cookies.get('Userid');
  const token = Cookies.get('AuthToken');

  useEffect(() => {
    const fetchMemberData = async () => {
      if (!userId || !token) {
        setMessage('Authentication required. Please log in.');
        return;
      }

      try {
        setIsLoading(true);
        const response = await axios.get(
          `${import.meta.env.VITE_API_BASE_URL}/api/users/members/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const userData = response.data.user || {};
        setFormData((prev) => ({
          ...prev,
          memId: userData.membershipId || '',
          name: userData.name || '',
          contactNo: userData.contactNo || '',
          unit: userData.unit || '',
          address: userData.address || '',
          profession: userData.profession || '',
        }));

        setMessage('');
      } catch (error) {
        setMessage(
          error.response?.data?.message ||
            'Failed to fetch member information. Please try again.'
        );
      } finally {
        setIsLoading(false);
      }
    };

    fetchMemberData();
  }, [userId, token]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handlePasswordChange = (e) => {
    setNewPassword(e.target.value);
  };

  const handleProfileSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const response = await axios.put(
        `${import.meta.env.VITE_API_BASE_URL}/api/users/members/${userId}`,
        formData,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setMessage('Profile successfully updated!');
      setTimeout(() => setMessage(''), 3000);
    } catch (error) {
      setMessage(
        error.response?.data?.message ||
          error.response?.data?.error ||
          'Error updating profile. Please try again.'
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    if (!newPassword) {
      setMessage('Please enter a new password.');
      return;
    }

    try {
      setPasswordLoading(true);

      const response = await axios.patch(
        `${import.meta.env.VITE_API_BASE_URL}/api/users/update-password`,
        { newPassword, userId }, // Ensure this matches the backend's expected field name
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        setMessage('Password successfully updated!');
      } else {
        setMessage('Failed to update password.');
      }

      setNewPassword(''); // Clear password field
      setTimeout(() => setMessage(''), 3000);
    } catch (error) {
      setMessage(
        error.response?.data?.message ||
          'Error updating password. Please try again.'
      );
    } finally {
      setPasswordLoading(false);
    }
  };

  return (
    <div
      id="MemberModification"
      className="min-h-screen bg-gray-50 p-4 flex flex-col justify-center items-center"
    >
      <div className="max-w-2xl mx-auto bg-white rounded-lg shadow p-6">
        <h2 className="text-2xl font-bold text-center text-gray-800 mb-6">
          Member Information
        </h2>

        {message && (
          <div
            className={`mb-4 p-3 rounded ${
              message.includes('Error') || message.includes('failed')
                ? 'bg-red-100 text-red-700'
                : 'bg-green-100 text-green-700'
            }`}
          >
            {message}
          </div>
        )}

        <form onSubmit={handleProfileSubmit} className="space-y-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label
                htmlFor="memId"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Member ID
              </label>
              <input
                type="text"
                id="memId"
                name="memId"
                value={formData.memId}
                readOnly
                className="w-full px-3 py-2 border border-gray-300 rounded-md bg-gray-100"
              />
            </div>

            <div>
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
                placeholder="Enter full name"
              />
            </div>

            <div>
              <label
                htmlFor="contactNo"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Contact Number
              </label>
              <input
                type="text"
                id="contactNo"
                name="contactNo"
                value={formData.contactNo}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
                placeholder="Enter contact number"
              />
            </div>

            <div>
              <label
                htmlFor="unit"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Unit
              </label>
              <input
                type="text"
                id="unit"
                name="unit"
                value={formData.unit}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
                placeholder="Enter unit"
              />
            </div>

            <div>
              <label
                htmlFor="address"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Address
              </label>
              <input
                type="text"
                id="address"
                name="address"
                value={formData.address}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
                placeholder="Enter address"
              />
            </div>

            <div>
              <label
                htmlFor="profession"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Profession
              </label>
              <input
                type="text"
                id="profession"
                name="profession"
                value={formData.profession}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
                placeholder="Enter profession"
              />
            </div>
          </div>

          <div className="mt-6">
            <button
              type="submit"
              disabled={isLoading}
              className={`w-full py-2 px-4 rounded-md ${
                isLoading
                  ? 'bg-blue-300 cursor-not-allowed'
                  : 'bg-blue-500 hover:bg-blue-600 text-white'
              }`}
            >
              {isLoading ? 'Submitting...' : 'Update Profile'}
            </button>
          </div>
        </form>

        <form onSubmit={handlePasswordSubmit} className="space-y-4 mt-6">
          <h3 className="text-lg font-bold text-gray-800">Change Password</h3>
          <div>
            <label
              htmlFor="newPassword"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              New Password
            </label>
            <input
              type="password"
              id="newPassword"
              value={newPassword}
              onChange={handlePasswordChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md"
              placeholder="Enter new password"
            />
          </div>

          <div>
            <button
              type="submit"
              disabled={passwordLoading}
              className={`w-full py-2 px-4 rounded-md ${
                passwordLoading
                  ? 'bg-blue-300 cursor-not-allowed'
                  : 'bg-green-500 hover:bg-green-600 text-white'
              }`}
            >
              {passwordLoading ? 'Updating...' : 'Update Password'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default MemberModification;