import { 
  BrowserRouter, 
  Routes, 
  Route,
} from 'react-router-dom';

// import './App.css';
import Navbar from './Components/Navbar';
import Home from './Components/Home';
import AboutUs from './Components/About';
import ActivityPage from './Components/Activity';
import Member_ship from './Components/Member_ship';
import Photogallery from './Components/Photogallery';
import Journal from './Components/Journal';
import JournalDetail from './Components/JournalDetail';
import Contactus from './Components/Contactus';
import Footer from './Components/Footer';
import ExecutiveCouncil from './Components/ExecutiveCouncil';
import ExternalLinks from './Components/ExternalLinks';

// Admin imports
import AdminHome from './Components/admin/Home';
import AdminNavbar from './Components/admin/Navbar';
import AdminAboutUs from './Components/admin/Aboutus';
import AdminActivitiesPage from './Components/admin/Activity';
import AdminExecutiveCouncil from './Components/admin/Executive';
import AdminPhotogallery from './Components/admin/Photogallery';
import AdminJournal from './Components/admin/Journal';
import AdminLogin from './Components/admin/Adminlogin';
import MemberTable from './Components/admin/MemberTable';
import AdminExternalLinks from './Components/admin/ExternalLinks';
import ExcelUploader from './Components/admin/Login';

import MemberModification from './Components/MemberModification';
import MembershipHandle from './Components/admin/MembershipHandle';

function App() {
  return (
    <BrowserRouter future={{ 
      v7_startTransition: true,
      v7_relativeSplatPath: true
    }}>
      <Routes>
        {/* Main Website Routes */}
        <Route
          path="/"
          element={
            <div className="font-title">
              <Navbar />
              <Home />
              <AboutUs />
              <Photogallery />
              <ActivityPage />
              <Member_ship />
              <Journal />
              <div className="mb-20 md:mb-0 md:h-[100vh] xl:h-[90vh]">
                <Contactus />
              </div>
              <Footer />
            </div>
          }
        />

        <Route
          path="/external-links"
          element={
            <>
           
              <ExternalLinks/>
            
            </>
          }
        />

        <Route
          path="/journal/:id"
          element={
            <>
              <Navbar />
              <JournalDetail />
              <Footer />
            </>
          }
        />

        <Route path="/executive" element={<ExecutiveCouncil />} />

        {/* Admin Routes */}
        <Route path='/admin/login' element={<AdminLogin/>}/>
        <Route path="/authorized/*" element={
          <div className='font-title'>
            <AdminNavbar /> 
            <Routes>
              <Route path="/" element={
                <div>
                  <AdminHome />
                  <ExcelUploader/>
                  <AdminAboutUs/>


                  

              
                </div>
              } />
              <Route path="/photos" element={<AdminPhotogallery />} />
              <Route path="/journal" element={<AdminJournal />} />
              <Route path="/activity" element={<AdminActivitiesPage/>}/>
              <Route path='/executive' element={ <AdminExecutiveCouncil/>}/>
              <Route path='/photos' element={ <AdminPhotogallery/>}/>
              <Route path='/external-links' element={<AdminExternalLinks />} />             
              <Route path='/membership' element={<MembershipHandle />} />             

            </Routes>
          </div>
        }/>
          <Route path="/executive" element={<ExecutiveCouncil />} />
          <Route path='/memberList' element={ <MemberTable/>}/>
          <Route path='/MemberModification' element={ <MemberModification/>}/>

        <Route path="/admin" element={<AdminNavbar />}>
          <Route path="home" element={<AdminHome />} />
          <Route path="journal" element={<AdminJournal />} />
          <Route path="executive" element={<AdminExecutiveCouncil />} />
          <Route path="aboutus" element={<AdminAboutUs />} />
          <Route path="login" element={<ExcelUploader />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
