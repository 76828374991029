import React, { useState, useEffect } from 'react';
import axios from 'axios';

const AdminAboutUs = () => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [previewUrls, setPreviewUrls] = useState({
    topImage: '/default-top.jpg',
    middleImage: '/default-middle.jpg',
    bottomImage: '/default-bottom.jpg',
  });

  useEffect(() => {
    // Load the Cloudinary widget script
    const script = document.createElement('script');
    script.src = 'https://upload-widget.cloudinary.com/global/all.js';
    script.async = true;
    document.body.appendChild(script);

    script.onerror = () => {
      console.error('Failed to load Cloudinary widget script');
    };

    // Fetch current images from the backend
    const fetchCurrentImages = async () => {
      try {
        const response = await axios.get(
          `${import.meta.env.VITE_API_BASE_URL}/api/aboutus`
        );

        if (response.data) {
          setPreviewUrls({
            topImage: response.data.topImage || '/default-top.jpg',
            middleImage: response.data.middleImage || '/default-middle.jpg',
            bottomImage: response.data.bottomImage || '/default-bottom.jpg',
          });
        }
      } catch (error) {
        console.error('Error fetching current images:', error);
        setMessage('Failed to load current images.');
      }
    };

    fetchCurrentImages();

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handleUpload = (imageType) => {
    if (!window.cloudinary) {
      console.error('Cloudinary widget is not loaded');
      return;
    }

    const widget = window.cloudinary.createUploadWidget(
      {
        cloudName: import.meta.env.VITE_CLOUDINARY_CLOUD_NAME,
        uploadPreset: import.meta.env.VITE_CLOUDINARY_UPLOAD_PRESET,
        folder: 'aboutus',
        maxFiles: 1,
        sources: ['local', 'url', 'camera'],
        clientAllowedFormats: ['jpg', 'jpeg', 'png'],
        resourceType: 'image',
        maxImageFileSize: 5000000, // 5MB
      },
      (error, result) => {
        if (error) {
          console.error('Upload error:', error);
          setMessage('Upload failed: ' + error.message);
          return;
        }

        if (result.event === 'success') {
          const uploadedUrl = result.info.secure_url;

          axios
            .post(`${import.meta.env.VITE_API_BASE_URL}/api/aboutus`, {
              [imageType]: uploadedUrl,
            })
            .then((response) => {
              setPreviewUrls((prevState) => ({
                ...prevState,
                [imageType]: response.data.imageUrl || uploadedUrl,
              }));
              setMessage(`${imageType} uploaded successfully!`);
            })
            .catch((err) => {
              console.error('Backend error:', err.response?.data || err.message);
              setMessage(`Error saving ${imageType}: ${err.message}`);
            });
        }
      }
    );

    widget.open();
  };

  return (
    <div className="p-6 mt-4 max-w-5xl mx-auto bg-white shadow-md rounded-lg">
      <h2 className="text-3xl font-semibold mb-6 text-center text-gray-800">
        Manage About Us Images
      </h2>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {['topImage', 'middleImage', 'bottomImage'].map((type) => (
          <div
            key={type}
            className="flex flex-col items-center border border-gray-200 p-4 rounded-lg shadow-sm hover:shadow-md transition-shadow duration-300"
          >
            <h3 className="text-lg font-medium mb-4 text-gray-700 capitalize">
              {type.replace('Image', '')} Image
            </h3>

            <div className="w-full h-44 flex items-center justify-center mb-4">
              <img
                src={previewUrls[type]}
                alt={`${type} preview`}
                className="w-full h-full object-cover rounded-lg shadow-md"
              />
            </div>

            <button
              onClick={() => handleUpload(type)}
              className="bg-blue-600 text-white px-5 py-2 rounded-md hover:bg-blue-700 transition-colors duration-200"
            >
              Upload Image
            </button>
          </div>
        ))}
      </div>

      {loading && <p className="text-blue-600 mt-4 text-center">Saving...</p>}

      {message && (
        <p
          className={`mt-6 text-center text-lg ${
            message.includes('Error') ? 'text-red-600' : 'text-green-600'
          }`}
        >
          {message}
        </p>
      )}
    </div>
  );
};

export default AdminAboutUs;