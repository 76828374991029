import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { setAdminAuthToken, getAdminAuthToken } from '../../utilis/auth';
import { FaEye, FaEyeSlash } from "react-icons/fa";

const AdminLogin = () => {
  const [credentials, setCredentials] = useState({
    email: '',
    password: ''
  });
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const [reveal, setReveal] = useState(false)

  const handleReveal = () => {
    setReveal((prev)=>!prev)
    console.log(reveal)
  }

  useEffect(() => {
    // Check if admin is already logged in
    const adminToken = getAdminAuthToken();
    if (adminToken) {
      // Redirect to the authorized page
      navigate('/authorized');
    }
  }, [navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    try {
      const response = await axios.post(
        `${import.meta.env.VITE_API_BASE_URL}/api/users/admin/login`,
        credentials
      );

      // Extract user and token details from the response
      const { token, user } = response.data;
      setAdminAuthToken(token);

      // Navigate to the authorized page with user details
      navigate('/authorized', { state: { user, token } });
    } catch (err) {
      const errorMessage =
        err.response?.data?.message || 'An error occurred. Please try again.';
      setError(errorMessage);
    }
  };

  const handleChange = (e) => {
    setCredentials({
      ...credentials,
      [e.target.name]: e.target.value
    });
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100 p-4">
      <div className="bg-white rounded-lg shadow-md w-full max-w-md p-6">
        <h1 className="text-2xl font-bold text-center mb-6">Admin Login</h1>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="space-y-2">
            <div className="relative">
              <input
                type="email"
                name="email"
                placeholder="Email"
                className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                value={credentials.email}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          <div className="space-y-2">
            <div className="relative flex justify-center items-center gap-3">
              <input
                type={reveal ? "text" : "password"} 
                name="password"
                placeholder="Password"
                className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                value={credentials.password}
                onChange={handleChange}
                required
              /> 
              <div onClick={handleReveal} className="div">
              {reveal ? <FaEye /> : <FaEyeSlash />}
              </div>
            </div>
          </div>

          {error && (
            <div className="text-red-500 text-sm text-center">
              {error}
            </div>
          )}

          <button
            type="submit"
            className="w-full bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          >
            Login
          </button>
        </form>
        
      </div>
      
    <div className="flex justify-center mt-4 mb-8">
      <Link 
        to="/" 
        className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition-colors duration-300 text-center"
      >
        Back to Home
      </Link>
    </div>
    </div>
    
  );
};

export default AdminLogin;