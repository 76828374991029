import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {  clearAdminAuthToken } from '../../utilis/auth'; // Adjust the path based on your project structure
import Notifications from './Notifications';

const AdminNavbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const navigate = useNavigate();

  const handleLogout = () => {
    clearAdminAuthToken(); // Clear admin-specific authentication cookies
    navigate('/admin/login'); // Redirect to the login page
  };

  return (
    <nav className="bg-gray-800 text-white p-4">
      <div className="container mx-auto flex justify-between items-center">
        {/* Logo/Brand */}
        <Link to="/admin" className="text-xl font-bold">
          Admin Dashboard
        </Link>

        {/* Hamburger Menu for Small Screens */}
        <button
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          className="block md:hidden text-white focus:outline-none"
        >
          <svg
            className="w-6 h-6"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            {isMenuOpen ? (
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            ) : (
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16m-7 6h7"
              />
            )}
          </svg>
        </button>

        {/* Links for Larger Screens */}
        <div
          className={`${
            isMenuOpen ? 'block' : 'hidden'
          } md:flex md:gap-4 md:items-center absolute md:static top-16 left-0 w-full md:w-auto bg-gray-800 md:bg-transparent p-4 md:p-0 z-50 space-y-4 md:space-y-0`}
        >
          <Link to="/authorized" className="block md:inline hover:text-gray-300">
            Dashboard
          </Link>
          <Link to="/authorized/journal" className="block md:inline hover:text-gray-300">
            Journal
          </Link>
          <Link to="/authorized/activity" className="block md:inline hover:text-gray-300">
            Activity
          </Link>
          <Link to="/authorized/executive" className="block md:inline hover:text-gray-300">
            Executive Council
          </Link>
          <Link to="/authorized/photos" className="block md:inline hover:text-gray-300">
            Gallery
          </Link>
          <Link to="/authorized/membership" className="block md:inline hover:text-gray-300">
            MemberShip
          </Link>
          <Link to="/authorized/external-links" className="block md:inline hover:text-gray-300">
            External Links
          </Link>
          <Link to="/" className="block md:inline hover:text-gray-300">
            View Site
          </Link>
          <button
            onClick={handleLogout}
            className="block md:inline hover:text-gray-300"
          >
            Logout
          </button>
        </div>

        {/* Add Notification Button */}
        <div className="flex items-center">
          <button
            onClick={() => setShowNotifications(!showNotifications)}
            className="relative p-2 text-white hover:text-gray-900"
          >
            <span className="sr-only">Notifications</span>
            <svg
              className="h-6 w-6"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"></path>
            </svg>
          </button>
        </div>

        {/* Notification Panel */}
        {showNotifications && (
          <div className="fixed inset-0 bg-black bg-opacity-50 z-50">
            <div className="absolute right-0 top-0 h-full w-96 bg-white shadow-lg overflow-y-auto">
              <div className="flex justify-between items-center p-4 border-b">
                <button
                  onClick={() => setShowNotifications(false)}
                  className="text-gray-500 hover:text-gray-700"
                >
                  <span className="sr-only">Close</span>
                  <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
              <Notifications />
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

export default AdminNavbar;